import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import queryString from "query-string";
import { CONSTANTS } from "shared/constants";
import { useTranslation } from "react-i18next";
import API from "core/api";
import {
  DeleteForumModal,
  ForumModal,
  ThreadCard,
  ThreadModal,
} from "apps/forums/components";
import { Page } from "components/common";

const ForumPage = ({
  auth,
  threads,
  defaultSearchValue,
  history,
  location,
  match: {
    params: { forumSlug },
  },
}) => {
  const [forum, setForum] = React.useState(null);
  const [threadsList, setThreadsList] = React.useState([]);
  const [threadModalVisible, setThreadModalVisible] = React.useState(false);
  const [forumModalVisible, setForumModalVisible] = React.useState(false);
  const [deleteForumModalVisible, setDeleteForumModalVisible] =
    React.useState(false);
  const [searchValue, setSearchValue] = React.useState(defaultSearchValue);
  const [limit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [moreThreads, setMoreThreads] = React.useState(true);
  const { t } = useTranslation();
  React.useEffect(() => {
    getForum();
    getMoreThreads();
    setSearchValue(defaultSearchValue);
    // eslint-disable-next-line
  }, [defaultSearchValue]);

  const getSearchParams = () => queryString.parse(location.search);

  const getForum = () => {
    threads.getForum(forumSlug, ({ data, status }) => {
      setForum(status === 200 ? data : null);
    });
  };

  const getThreads = () => {
    setOffset(0);
    const params = {
      search: getSearchParams()?.search,
      limit: limit,
      offset: 0,
    };
    threads.getThreads(forumSlug, params, ({ data, status }) => {
      const sortedThreads = status === 200 ? [...data.results] : null;
      setThreadsList(sortedThreads);
      setOffset(offset + limit);
      data.next === null ? setMoreThreads(false) : setMoreThreads(true);
    });
  };

  const getMoreThreads = () => {
    const params = {
      search: getSearchParams()?.search,
      limit: limit,
      offset: offset,
    };
    threads.getThreads(forumSlug, params, ({ data, status }) => {
      const sortedThreads =
        status === 200 ? [...threadsList, ...data.results] : null;
      setThreadsList(sortedThreads);
      setOffset(offset + limit);
      data.next === null ? setMoreThreads(false) : setMoreThreads(true);
    });
  };

  const closeModalThread = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getThreads();
    }
    setThreadModalVisible(false);
  };

  const closeModalDeleteForum = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getThreads();
    }
    setDeleteForumModalVisible(false);
  };

  const closeModalForum = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getForum();
    }
    setForumModalVisible(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const params = {
      ...queryString.parse(location.search),
      search: searchValue !== "" ? searchValue : undefined,
    };
    history.push(
      `${CONSTANTS.URLS.FORUM.FORUM_DETAIL(forumSlug)}?${queryString.stringify(
        params
      )}`
    );
  };

  const ownerIsLoggedUser = () => {
    return auth.user?.slug === forum?.owner?.slug;
  };

  const renderThreads = () =>
    threadsList.map((thread, key) => {
      return <ThreadCard forum_slug={forumSlug} thread={thread} key={key} />;
    });

  if (forum)
    return (
      <main className="pz-forum">
        <Navbar />

        <section className="pz-forum__page">
          (
          <>
            <section className="pz-forum__content">
              <section className="pz-forum__header">
                {forum?.thumbnail ? (
                  <Image
                    src={forum?.thumbnail}
                    className="pz-forum__header--image"
                  />
                ) : (
                  <Image
                    name="ilustrations/forum.svg"
                    className="pz-forum__header--image"
                  />
                )}

                <section>
                  <h1>{forum?.name}</h1>
                  <h4>{forum?.description}</h4>
                </section>
                {ownerIsLoggedUser() ? (
                  <section className="grid-columns grid-gap-20 grid-content-left">
                    <Button
                      thin
                      ghost
                      primary
                      onClick={() => setForumModalVisible(true)}
                    >
                      {t("forum.forum.btn_edit")}
                    </Button>
                    <Button
                      thin
                      onClick={() => setDeleteForumModalVisible(true)}
                    >
                      {t("forum.forum.btn_delete")}
                    </Button>
                  </section>
                ) : null}
              </section>
              <section className="pz-forum__threads">
                <h4 className="pz-forum__threads--title">
                  <form onSubmit={handleSubmit2} className="pz-forum__search">
                    <input
                      type="text"
                      value={searchValue}
                      onChange={handleChange}
                      className="pz-forum__search--input"
                      placeholder={t("forum.forum.input_search")}
                    />
                  </form>
                  {API.auth.isLoggedIn() ? (
                    <Button
                      thin
                      ghost
                      primary
                      onClick={() => {
                        setThreadModalVisible(true);
                      }}
                    >
                      {t("forum.forum.btn_create")}
                    </Button>
                  ) : (
                    <p>{t("forum.forum.btn_create_logout")}</p>
                  )}
                </h4>
                {true ? (
                  <>
                    <section className="pz-forum__threads--list">
                      {renderThreads()}
                    </section>

                    <section className="grid grid-content-center">
                      {moreThreads ? (
                        <Button primary thin onClick={getMoreThreads}>
                          {t("courses.more_courses")}
                        </Button>
                      ) : (
                        <Button disabled thin>
                          {t("courses.no_more_courses")}
                        </Button>
                      )}
                    </section>
                  </>
                ) : (
                  <section className="pz-forum__threads--list empty">
                    <span>{"No ha noticias del banner"}</span>
                  </section>
                )}
              </section>
            </section>
            <ThreadModal
              currentNews={null}
              forum_id={forum?.slug}
              is_visible={threadModalVisible}
              closeModal={closeModalThread}
            />
            <ForumModal
              forum={forum}
              is_visible={forumModalVisible}
              closeModal={closeModalForum}
            />
            <DeleteForumModal
              forumSlug={forum?.slug}
              is_visible={deleteForumModalVisible}
              closeModal={closeModalDeleteForum}
              courseSlug={forum?.course?.slug}
            />
          </>
          )
        </section>
      </main>
    );
  else
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
};

export default connectReduxReducers(withRouter(ForumPage), "threads", "auth");
