import React from "react";
import { Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";
import { NavbarAdmin } from "apps/admin/components";
import { Button, DateField, Form } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";

const AdminGeneralPage = ({ auth, news }) => {
  const [coursesList, setCoursesList] = React.useState([]);
  const [interactionList, setInteractionList] = React.useState([]);
  const [viewsList, setViewsList] = React.useState([]);
  const [toggleState, setToggleState] = React.useState(1);
  const { t } = useTranslation();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getCourses = (data) => {
    const params = {
      start_date: data.startDate,
      end_date: data.endDate,
    };
    news.getAdminGeneral(params, ({ data, status }) => {
      setCoursesList(status === 200 ? data : null);
    });
  };

  const getInteractions = (data) => {
    const params = {
      start_date: data.startDate,
      end_date: data.endDate,
    };
    news.getAdminInteraction(params, ({ data, status }) => {
      setInteractionList(status === 200 ? data : null);
    });
  };

  const getViews = (data) => {
    const params = {
      start_date: data.startDate,
      end_date: data.endDate,
    };
    news.getAdminViews(params, ({ data, status }) => {
      setViewsList(status === 200 ? data : null);
    });
  };

  const renderTabContent = () => {
    switch (toggleState) {
      case 1:
        return (
          <>
            <section className="pz-admin__form">
              <Form onSubmit={getCourses}>
                {({ onChange, data: { introduction } }) => (
                  <React.Fragment>
                    <div className="pz-admin__dates">
                      <DateField
                        label={t(`admin.form.start_date`)}
                        name="startDate"
                        onChange={onChange}
                      />
                      <DateField
                        label={t(`admin.form.end_date`)}
                        name="endDate"
                        onChange={onChange}
                      />
                    </div>
                    <section className="pz-auth-form__buttons">
                      <Button type="submit" primary thin>
                        {t(`admin.form.button`)}
                      </Button>
                    </section>
                  </React.Fragment>
                )}
              </Form>
            </section>
            <section className="pz-admin__list">
              {coursesList.map((item, index) => {
                let previousItem = coursesList[index - 1]; // Get the previous item

                // Function to get comparison icon
                const getComparisonIcon = (current, previous) => {
                  if (previous === undefined) return null; // Skip comparison for first item
                  if (current > previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    );
                  } else if (current < previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    );
                  } else {
                    return (
                      <FontAwesomeIcon
                        icon={faEquals}
                        style={{ color: "gray" }}
                      />
                    );
                  }
                };
                return (
                  <section className="grid" key={index}>
                    <h3>
                      Mes {item.month} Año {item.year}
                    </h3>
                    <table className="pz-landing__audio--visual__table">
                      <thead>
                        <tr className="active-row">
                          <th>Objeto</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>Cursos</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.courses}{" "}
                              {getComparisonIcon(
                                item.courses,
                                previousItem?.courses
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Modulos</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.modules}{" "}
                              {getComparisonIcon(
                                item.modules,
                                previousItem?.modules
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Contenidos</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.contents}{" "}
                              {getComparisonIcon(
                                item.contents,
                                previousItem?.contents
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Quizzes</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.quizzes}{" "}
                              {getComparisonIcon(
                                item.quizzes,
                                previousItem?.quizzes
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Aportes</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.practicals}{" "}
                              {getComparisonIcon(
                                item.practicals,
                                previousItem?.practicals
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Educlips</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.posts}{" "}
                              {getComparisonIcon(
                                item.posts,
                                previousItem?.posts
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Forors</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.forums}{" "}
                              {getComparisonIcon(
                                item.forums,
                                previousItem?.forums
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Preguntas</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.threads}{" "}
                              {getComparisonIcon(
                                item.threads,
                                previousItem?.threads
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Respuestas</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.responses}{" "}
                              {getComparisonIcon(
                                item.responses,
                                previousItem?.responses
                              )}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                );
              })}
            </section>
          </>
        );

      case 2:
        return (
          <>
            <section className="pz-admin__form">
              <Form onSubmit={getInteractions}>
                {({ onChange, data: { introduction } }) => (
                  <React.Fragment>
                    <div className="pz-admin__dates">
                      <DateField
                        label={t(`admin.form.start_date`)}
                        name="startDate"
                        onChange={onChange}
                      />
                      <DateField
                        label={t(`admin.form.end_date`)}
                        name="endDate"
                        onChange={onChange}
                      />
                    </div>
                    <section className="pz-auth-form__buttons">
                      <Button type="submit" primary thin>
                        {t(`admin.form.button`)}
                      </Button>
                    </section>
                  </React.Fragment>
                )}
              </Form>
            </section>
            <section className="pz-admin__list">
              {interactionList.map((item, index) => {
                let previousItem = interactionList[index - 1];

                // Define a function to compare and return the appropriate icon
                const getComparisonIcon = (current, previous) => {
                  if (previous === undefined) return null;
                  if (current > previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    );
                  } else if (current < previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    );
                  } else {
                    return (
                      <FontAwesomeIcon
                        icon={faEquals}
                        style={{ color: "gray" }}
                      />
                    );
                  }
                };
                return (
                  <section className="grid" key={index}>
                    <h3>
                      Mes {item.month} Año {item.year}
                    </h3>
                    <table className="pz-landing__audio--visual__table">
                      <thead>
                        <tr className="active-row">
                          <th>Objeto</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>Commentarios</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.comments}{" "}
                              {getComparisonIcon(
                                item.comments,
                                previousItem?.comments
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Cuestionarios</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.questionnaires}{" "}
                              {getComparisonIcon(
                                item.questionnaires,
                                previousItem?.questionnaires
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Guardados</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.coursessubs}{" "}
                              {getComparisonIcon(
                                item.coursessubs,
                                previousItem?.coursessubs
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Quiz Resultados</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.quizzesattempts}{" "}
                              {getComparisonIcon(
                                item.quizzesattempts,
                                previousItem?.quizzesattempts
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Reacciones</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.reactions}{" "}
                              {getComparisonIcon(
                                item.reactions,
                                previousItem?.reactions
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Trivia Resultados</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.triviasresults}{" "}
                              {getComparisonIcon(
                                item.triviasresults,
                                previousItem?.triviasresults
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Usuarios</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.users}{" "}
                              {getComparisonIcon(
                                item.users,
                                previousItem?.users
                              )}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                );
              })}
            </section>
          </>
        );
      case 3:
        return (
          <>
            <section className="pz-admin__form">
              <Form onSubmit={getViews}>
                {({ onChange, data: { introduction } }) => (
                  <React.Fragment>
                    <div className="pz-admin__dates">
                      <DateField
                        label={t(`admin.form.start_date`)}
                        name="startDate"
                        onChange={onChange}
                      />
                      <DateField
                        label={t(`admin.form.end_date`)}
                        name="endDate"
                        onChange={onChange}
                      />
                    </div>
                    <section className="pz-auth-form__buttons">
                      <Button type="submit" primary thin>
                        {t(`admin.form.button`)}
                      </Button>
                    </section>
                  </React.Fragment>
                )}
              </Form>
            </section>
            <section className="pz-admin__list">
              {viewsList.map((item, index) => {
                let previousItem = viewsList[index - 1]; // Access the previous item

                // Define a function to compare and return the appropriate icon
                const getComparisonIcon = (current, previous) => {
                  if (previous === undefined) return null; // No comparison for the first item
                  if (current > previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowUp}
                        style={{ color: "green" }}
                      />
                    );
                  } else if (current < previous) {
                    return (
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        style={{ color: "red" }}
                      />
                    );
                  } else {
                    return (
                      <FontAwesomeIcon
                        icon={faEquals}
                        style={{ color: "gray" }}
                      />
                    );
                  }
                };
                return (
                  <section className="grid" key={index}>
                    <h3>
                      Mes {item.month} Año {item.year}
                    </h3>
                    <table className="pz-landing__audio--visual__table">
                      <thead>
                        <tr className="active-row">
                          <th>Objeto</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h4>Vistas</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.views}{" "}
                              {getComparisonIcon(
                                item.views,
                                previousItem?.views
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Vistas cursos</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.courses_views}{" "}
                              {getComparisonIcon(
                                item.courses_views,
                                previousItem?.courses_views
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Vistas Contenidos</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.contents_views}{" "}
                              {getComparisonIcon(
                                item.contents_views,
                                previousItem?.contents_views
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Vistas aportes</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.practicals_views}{" "}
                              {getComparisonIcon(
                                item.practicals_views,
                                previousItem?.practicals_views
                              )}
                            </h4>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Educlips Vistas</h4>{" "}
                          </td>
                          <td>
                            <h4>
                              {item.posts_views}{" "}
                              {getComparisonIcon(
                                item.posts_views,
                                previousItem?.posts_views
                              )}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                );
              })}
            </section>
          </>
        );

      default:
        return <></>;
    }
  };

  return (
    <main className="pz-admin">
      <Navbar />

      <section className="pz-admin__page">
        {auth.user.is_staff ? (
          <>
            <NavbarAdmin index={0} />

            <section className="pz-admin__content">
              <section className="pz-admin__tabcontainer">
                <section className="pz-admin__tabs">
                  <h4
                    className={
                      toggleState === 1
                        ? "pz-admin__active-tab"
                        : "pz-admin__tab"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    contenido
                  </h4>
                  <h4
                    className={
                      toggleState === 2
                        ? "pz-admin__active-tab"
                        : "pz-admin__tab"
                    }
                    onClick={() => toggleTab(2)}
                  >
                    interaccion
                  </h4>
                  {
                    <h4
                      className={
                        toggleState === 3
                          ? "pz-admin__active-tab"
                          : "pz-admin__tab"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      vistas
                    </h4>
                  }
                </section>
              </section>

              {renderTabContent()}
            </section>
          </>
        ) : (
          <>No tienes permiso para entrar aca</>
        )}
      </section>
    </main>
  );
};

export default connectReduxReducers(AdminGeneralPage, "auth", "news");
