import {
  ChangePasswordPage,
  ChangePasswordPage2,
  ForgotPasswordPage,
  LoginPage,
  SignupCompanyPage,
  SignupPage,
} from "./pages";
import { LoggedGuard, UnLoggedGuard } from "hocs";

const Routes = [
  {
    path: "/login",
    component: UnLoggedGuard(LoginPage),
  },
  {
    path: "/forgot-password",
    component: UnLoggedGuard(ForgotPasswordPage),
  },
  {
    path: "/change-password",
    component: UnLoggedGuard(ChangePasswordPage),
  },
  {
    path: "/change-password2",
    component: LoggedGuard(ChangePasswordPage2),
  },
  {
    path: "/signup",
    component: UnLoggedGuard(SignupPage),
  },
  {
    path: "/signup/company",
    component: UnLoggedGuard(SignupCompanyPage),
  },
];

export default Routes;
