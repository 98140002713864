import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { withRouter } from "react-router-dom";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import { Image, Line } from "components/ui";
import Button from "components/ui/button";
import {
  ContentModal,
  CourseComments,
  CourseModal,
  CourseModules,
  CourseUserBadge,
  ModuleModal,
  Reactions,
  ConfirmationDeleteCourseModal,
  ConfirmationDeleteLessonModal,
  ConfirmationDeleteModuleModal,
  ViewCreation,
  QuizModal,
  QuizAttemptModal,
  ForumSection,
} from "apps/courses/components";
import { renderClasses } from "shared/common/functions";
import { CONSTANTS } from "shared/constants";
import ShareButton from "components/panzofi/sharebutton";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark as faBookmark2,
  faBox,
  faEdit,
  faFileImport,
  faGlobe,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import API from "core/api";
import AdSense from "apps/courses/components/ads";
import ConfirmationDeleteQuizModal from "apps/courses/components/confirmation-delete-quiz-modal";
import SlideToggle from "react-slide-toggle";
import eases from "eases";
const CoursePage = ({
  courses,
  history,
  match: {
    params: { courseSlug },
  },
  auth,
}) => {
  const googleFormsUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSfeuJvJNEhZwEXR9up8sJ6TMnkfOJG8PzmO_1taF_yZiZQNcA/viewform?usp=sf_link";

  const handleReportarContenido = () => {
    window.open(googleFormsUrl, "_blank"); // Abre el formulario en una nueva pestaña del navegador
  };

  const [lessonToEdit, setLessonToEdit] = React.useState(null);
  const [moduleToEdit, setModuleToEdit] = React.useState(null);
  const [quizToEdit, setQuizToEdit] = React.useState(null);
  const [quizToAttempt, setQuizToAttempt] = React.useState(null);
  const [courseModalVisible, setCourseModalVisible] = React.useState(false);
  const [moduleModalVisible, setModuleModalVisible] = React.useState(false);
  const [contentModalVisible, setContentModalVisible] = React.useState(false);
  const [quizModalVisible, setQuizModalVisible] = React.useState(false);
  const [deleteCourseModalVisible, setDeleteCourseModalVisible] =
    React.useState(false);
  const [deleteModuleModalVisible, setDeleteModuleModalVisible] =
    React.useState(false);
  const [deleteLessonModalVisible, setDeleteLessonModalVisible] =
    React.useState(false);
  const [deleteQuizModalVisible, setDeleteQuizModalVisible] =
    React.useState(false);
  const [course, setCourse] = React.useState(undefined);
  const [sub, setSub] = React.useState(false);
  const [attemptModalVisible, setAttemptModalVisible] = React.useState(false);
  const [employeeComments, setEmployeeComments] = React.useState([]);
  const [employeeReactions, setEmployeeReactions] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    getCourse();

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getCourse();
    // eslint-disable-next-line
  }, [auth.token]);

  const menuOptionsProfileMobile = [
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faBox} />,
      text: "Crear módulo",
      action: () => setModuleModalVisible(true),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faFileImport} />,
      text: "Subir contenido",
      action: () =>
        course?.modules.length > 0
          ? setContentModalVisible(true)
          : setModuleModalVisible(true),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faEdit} />,
      text: "Editar leccion",
      action: () => setCourseModalVisible(true),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faPencilAlt} />,
      text: "Crear quiz",
      action: () =>
        course?.modules.length > 0
          ? setQuizModalVisible(true)
          : setModuleModalVisible(true),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faTrash} />,
      text: "Eliminar leccion",
      action: () => setDeleteCourseModalVisible(true),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faGlobe} />,
      text: "Cambiar privacidad",
      action: () => {
        changePrivacity();
      },
    },
  ];

  const tagsArray =
    !!course && course.tags
      ? course.tags.split(",").map((tag) => tag.trim())
      : [];

  const renderMenuProfileOptionsMobile = (onToggle) =>
    menuOptionsProfileMobile.map(({ icon, text, action, type }, key) => {
      switch (type) {
        case "line":
          return <span key={key + 1} className="pz-navbar__menu-line" />;
        case "option":
          return (
            <span
              key={key + 1}
              onClick={() => {
                action();
                onToggle();
              }}
              className="pz-navbar__menu-item"
            >
              {icon} {text}
            </span>
          );
        default:
          return null;
      }
    });

  const getCourse = (slug = courseSlug) => {
    courses.getCourse(slug, getCourseCallback);
  };

  const createCourseSub = () => {
    const getCourseSubCallback = ({ status }) => {
      if (status === 201) {
        setSub(true);
      }
    };
    if (API.auth.isLoggedIn()) {
      courses.createCourseSubscription(course?.id, getCourseSubCallback);
    } else {
      history.push(CONSTANTS.URLS.AUTH.LOGIN);
    }
  };

  const deleteCourseSub = () => {
    const getCourseSubCallback3 = ({ status }) => {
      if (status === 204) {
        setSub(false);
      }
    };
    courses.deleteCourseSubscription(course?.id, getCourseSubCallback3);
  };

  const getCourseSub = (course_id) => {
    if (course_id && auth.user?.id) {
      courses.getCourseSubscription(course_id, getCourseSubCallback2);
    }
  };

  const getCourseEmployeesComments = (course_id) => {
    if (course_id && (auth.user?.employee || auth.user?.organization)) {
      courses.getCoursesEmployeesComments(course_id, (response) => {
        if (response.status === 200) {
          setEmployeeComments(response.data);
        }
      });
    }
  };

  const getCourseEmployeesReactions = (course_id) => {
    if (course_id && (auth.user?.employee || auth.user?.organization)) {
      courses.getCoursesEmployeesReactions(course_id, (response) => {
        if (response.status === 200) {
          setEmployeeReactions(response.data);
        }
      });
    }
  };

  const handleGetCourse = () => {
    getCourse();
  };

  const getCourseSubCallback2 = ({ status }) => {
    if (status === 200) {
      setSub(true);
    } else {
      setSub(false);
    }
  };

  const getCourseCallback = ({ data, status }) => {
    if (status === 200) {
      setCourse(data);
      getCourseSub(data.id);
      getCourseEmployeesComments(data.id);
      getCourseEmployeesReactions(data.id);
    } else {
      setCourse(null);
    }
  };

  const ownerIsLoggedUser = () => {
    return auth.user?.slug === course?.owner?.slug;
  };

  const closeModuleModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setModuleModalVisible(false);
    setModuleToEdit(null);
  };

  const closeContentModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setLessonToEdit(null);
    setContentModalVisible(false);
  };

  const closeQuizModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setQuizToEdit(null);
    setQuizModalVisible(false);
  };

  const closeConfirmationDeleteModal = () => {
    setDeleteCourseModalVisible(false);
  };

  const changePrivacity = () => {
    courses.modifyCourse(
      course?.slug,
      {
        is_draft: !course?.is_draft,
      },
      getCourseCallback
    );
  };

  const openModuleEditionModal = (module) => {
    setModuleToEdit(module);
    setModuleModalVisible(true);
  };

  const openModuleDeleteModal = (module) => {
    setModuleToEdit(module);
    setDeleteModuleModalVisible(true);
  };
  const closeModuleDeleteModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setModuleToEdit(null);
    setDeleteModuleModalVisible(false);
  };

  const openLessonEditionModal = (lesson) => {
    setLessonToEdit(lesson);
    setContentModalVisible(true);
  };

  const openQuizEditionModal = (quiz) => {
    setQuizToEdit(quiz);
    setQuizModalVisible(true);
  };

  const openLessonDeleteModal = (lesson) => {
    setLessonToEdit(lesson);
    setDeleteLessonModalVisible(true);
  };
  const closeLessonDeleteModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setLessonToEdit(null);
    setDeleteLessonModalVisible(false);
  };

  const closeQuizDeleteModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setQuizToEdit(null);
    setDeleteQuizModalVisible(false);
  };

  const openQuizDeleteModal = (quiz) => {
    setQuizToEdit(quiz);
    setDeleteQuizModalVisible(true);
  };

  const openQuizAttemptModal = (quiz) => {
    setQuizToAttempt(quiz);
    setAttemptModalVisible(true);
  };

  const closeQuizAttemptModal = (shouldGetCourse = false) => {
    if (shouldGetCourse) {
      getCourse();
    }
    setQuizToAttempt(null);
    setAttemptModalVisible(false);
  };

  const updateModalOrder = (module, order) => {
    const orderCallback = ({ status }) => {
      if (status === 200) {
        getCourse();
      }
    };
    courses.updateModuleOrder(
      course?.slug,
      module?.id,
      {
        module_id: module?.id,
        new_order: order + 1,
      },
      orderCallback
    );
  };

  const updateContentOrder = (module_id, lesson_id, order) => {
    const orderCallback = ({ status }) => {
      if (status === 200) {
        getCourse();
      }
    };
    courses.updateContentOrder(
      course?.slug,
      module_id,
      lesson_id,
      {
        content_id: lesson_id,
        new_order: order + 1,
      },
      orderCallback
    );
  };

  const renderLoginForm = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        <Button primary thin>
          Acciones
        </Button>
      </section>
      <section
        className="pz-navbar__menu-container2"
        ref={setCollapsibleElement}
      >
        {renderMenuProfileOptionsMobile(onToggle)}
      </section>
    </section>
  );

  if (
    course &&
    (ownerIsLoggedUser() || (!course?.is_draft && !ownerIsLoggedUser()))
  ) {
    return (
      <main className="pz-course">
        <Navbar />

        <Page className="pz-course__content">
          <header className="pz-course__header">
            <Image src={course?.thumbnail} className="pz-course__thumbnail" />
            <section className="pz-course__header--content">
              {ownerIsLoggedUser() && course?.is_draft ? (
                <h5 className="pz-course__badge--private">
                  {t("course.states.error")}
                </h5>
              ) : null}
              <h1>{course?.name}</h1>
              <p>{course?.description}</p>
              <section className="pz-course__header--info">
                {course.level ? (
                  <small>
                    <div className="pz-course__level">
                      {t("course.level")}:{" "}
                      <div className="pz-course__level--icon">
                        <div
                          className={`pz-course__level--icon--bar-1 pz-course__level--active`}
                        ></div>
                        <div
                          className={`pz-course__level--icon--bar-2 ${
                            course.level !== "BEGINNER"
                              ? "pz-course__level--active"
                              : "pz-course__level--inactive"
                          }`}
                        ></div>
                        <div
                          className={`pz-course__level--icon--bar-3 ${
                            course.level !== "INTERMEDIATE" &&
                            course.level !== "BEGINNER"
                              ? "pz-course__level--active"
                              : "pz-course__level--inactive"
                          }`}
                        ></div>
                      </div>
                    </div>
                  </small>
                ) : null}
                <small>
                  {t("course.number_modules")}: {course?.modules.length}
                </small>
                <small>
                  {t("course.updated")}:{" "}
                  {moment(course.updated).format("MMMM D, YYYY")}
                </small>
                {course?.language !== null ? (
                  <small>
                    {t("course.language")}: {course?.language}
                  </small>
                ) : (
                  <></>
                )}
              </section>
              <section className="pz-course__header--tags">
                {tagsArray.map((tag, key) => {
                  return (
                    <small className="pz-course__header--tags--tag" key={key}>
                      {tag}
                    </small>
                  );
                })}
              </section>
              <section className="pz-course__header--actions">
                <ShareButton />
                <FontAwesomeIcon
                  title={t("course.save")}
                  icon={sub === false ? faBookmark : faBookmark2}
                  className="pz-course__header--icon"
                  onClick={sub === false ? createCourseSub : deleteCourseSub}
                ></FontAwesomeIcon>
              </section>
            </section>
            <section className="pz-course__header--badges">
              {ownerIsLoggedUser() ? (
                course?.is_draft ? (
                  <>
                    <h5 className="pz-course__badge--private">
                      {t("course.states.private")}
                    </h5>
                    <Button alert thin onClick={changePrivacity}>
                      {t("course.buttons.states_button.make_public")}
                    </Button>
                  </>
                ) : (
                  <>
                    <h5 className="pz-course__badge">
                      {t("course.states.public")}
                    </h5>
                    <Button thin onClick={changePrivacity}>
                      {t("course.buttons.states_button.make_private")}
                    </Button>
                  </>
                )
              ) : null}
            </section>
          </header>
          {ownerIsLoggedUser() ? (
            <section className="pz-course__actions">
              <section className="pz-course__actions--pc">
                <Button
                  primary
                  thin
                  ghost
                  onClick={() => setCourseModalVisible(true)}
                >
                  {t("course.buttons.edit_lesson")}
                </Button>
                <Button
                  primary
                  thin
                  ghost
                  onClick={() => setModuleModalVisible(true)}
                >
                  {t("course.buttons.create_module")}
                </Button>
                <Button
                  primary
                  thin
                  ghost
                  onClick={() => setContentModalVisible(true)}
                  disabled={course?.modules.length === 0}
                >
                  {t("course.buttons.upload_content")}
                </Button>
                <Button
                  secondary
                  thin
                  ghost
                  onClick={() => setQuizModalVisible(true)}
                  disabled={course?.modules.length === 0}
                >
                  Crear quiz
                </Button>
                <ForumSection
                  forum={course?.forum}
                  owner={ownerIsLoggedUser()}
                  course_id={course?.id}
                  course_dec={course?.description}
                  course_name={course?.name}
                  hasModules={course?.modules.length > 0}
                />
                <Button thin onClick={() => setDeleteCourseModalVisible(true)}>
                  {t("course.buttons.delete_lesson")}
                </Button>
              </section>
              <section className="pz-course__actions--mobile">
                <SlideToggle
                  duration={500}
                  easeCollapse={eases["quintOut"]}
                  easeExpand={eases["quintOut"]}
                  render={renderLoginForm}
                  bestPerformance
                  collapsed
                />
              </section>
            </section>
          ) : (
            <section className="pz-course__actions"></section>
          )}
          <section
            className={renderClasses({
              "pz-course__modules": true,
              empty: !course?.modules?.length,
            })}
          >
            {course?.modules?.length ? (
              <CourseModules
                course={course}
                employee_tier={
                  auth.user.employee?.organization?.tier_plan?.name ===
                    "BASIC" ||
                  auth.user.employee?.organization?.tier_plan?.name === "FREE"
                    ? false
                    : true
                }
                onEditModule={
                  ownerIsLoggedUser() ? openModuleEditionModal : null
                }
                onDeleteModule={
                  ownerIsLoggedUser() ? openModuleDeleteModal : null
                }
                onEditLesson={
                  ownerIsLoggedUser() ? openLessonEditionModal : null
                }
                onDeleteLesson={
                  ownerIsLoggedUser() ? openLessonDeleteModal : null
                }
                onUpdateModuleOrder={
                  ownerIsLoggedUser() ? updateModalOrder : null
                }
                onUpdateContentOrder={
                  ownerIsLoggedUser() ? updateContentOrder : null
                }
                onDeleteQuiz={ownerIsLoggedUser() ? openQuizDeleteModal : null}
                onEditQuiz={ownerIsLoggedUser() ? openQuizEditionModal : null}
                onQuizAttempt={
                  auth.user.username
                    ? openQuizAttemptModal
                    : () => history.push(CONSTANTS.URLS.AUTH.LOGIN)
                }
              />
            ) : ownerIsLoggedUser() ? (
              <p>{t("course.no_mudules_logged")}</p>
            ) : (
              <p>{t("course.no_mudules_unlogged")}</p>
            )}
          </section>
          <section className="pz-course__extra">
            {course?.for_company ? null : (
              <CourseUserBadge user={course?.owner} />
            )}
            <Line />
            <Reactions
              reactionList={
                auth.user?.employee || auth.user?.organization
                  ? employeeReactions
                  : course?.reactions
              }
              model={CONSTANTS.APP_MODELS.COURSES}
              modelId={course?.id}
              onReact={handleGetCourse}
            />
            <Line className="pz-course__extra--linepad" />
            {course?.for_company ? null : (
              <>
                <AdSense />
                <Line />
              </>
            )}

            <CourseComments
              commentList={
                auth.user?.employee || auth.user?.organization
                  ? employeeComments
                  : course?.comments
              }
              model={CONSTANTS.APP_MODELS.COURSES}
              modelId={course?.id}
              onComment={handleGetCourse}
            />
            <Line />
            <Button onClick={handleReportarContenido}>
              {t("course.buttons.report_content")}
            </Button>
          </section>
        </Page>

        {ownerIsLoggedUser() ? (
          <>
            <CourseModal
              course={course}
              is_visible={courseModalVisible}
              closeModal={(slug, shouldGetCourse = false) => {
                if (shouldGetCourse) {
                  getCourse(slug);
                }
                setCourseModalVisible(false);
              }}
            />

            <ModuleModal
              moduleToEdit={moduleToEdit}
              is_visible={moduleModalVisible}
              closeModal={closeModuleModal}
              course={course}
            />

            <ContentModal
              lessonToEdit={lessonToEdit}
              is_visible={contentModalVisible}
              closeModal={closeContentModal}
              course={course}
              forCompany={auth.user?.organization ? true : false}
            />

            <QuizModal
              is_visible={quizModalVisible}
              closeModal={closeQuizModal}
              course={course}
              currentQuiz={quizToEdit}
            />

            <ConfirmationDeleteCourseModal
              is_visible={deleteCourseModalVisible}
              closeModal={closeConfirmationDeleteModal}
              courseSlug={courseSlug}
            />

            <ConfirmationDeleteModuleModal
              is_visible={deleteModuleModalVisible}
              closeModal={closeModuleDeleteModal}
              course={course}
              moduleToEdit={moduleToEdit}
            />
            <ConfirmationDeleteLessonModal
              lessonToEdit={lessonToEdit}
              is_visible={deleteLessonModalVisible}
              closeModal={closeLessonDeleteModal}
              course={course}
            />
            <ConfirmationDeleteQuizModal
              is_visible={deleteQuizModalVisible}
              closeModal={closeQuizDeleteModal}
              quiz_id={quizToEdit?.id}
            />
          </>
        ) : null}
        <QuizAttemptModal
          is_visible={attemptModalVisible}
          closeModal={closeQuizAttemptModal}
          quiz={quizToAttempt}
        />
        <ViewCreation
          id_content={course.id}
          model_content={CONSTANTS.APP_MODELS.COURSES}
        />
        <Footer />
      </main>
    );
  } else if (course?.is_draft && !ownerIsLoggedUser()) {
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__404">
          <h1 className="text-center">{t("notFound.no_public")}</h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  } else if (course === null) {
    return (
      <main className="pz-profile">
        <Navbar />

        <Page className="pz-profile__404">
          <h1 className="text-center">
            {t("notFound.no_exist.t1")}
            <br />
            {t("notFound.no_exist.t2")}
          </h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default withRouter(connectReduxReducers(CoursePage, "courses"));
