import React from "react";
import { Navbar, NavbarConfig } from "components/panzofi";
import { Button, Form, PasswordField } from "components/ui";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";
import { notEmpty, notSpaces, passVal } from "shared/utils/input-validations";
import { CONSTANTS } from "shared/constants";
import API from "core/api";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const SettingsPasswordPage = ({ auth, history }) => {
  const { t } = useTranslation();
  const passregex = /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/;

  const submitChangePassword = (data) => {
    if (!(data.password.match(passregex) && data.re_password.match(passregex)))
      return;
    const orderCallback = ({ status }) => {
      if (status === 200) {
        history.push(CONSTANTS.URLS.PROFILE.USER_LOGGED);
      }
    };
    const dataform = {
      ...data,
      user_id: API.auth.getUser()?.id,
    };
    auth.change_password2(dataform, orderCallback);
  };

  return (
    <main className="pz-settings">
      <Navbar />
      <section className="pz-settings__page">
        <NavbarConfig index={2} />
        <section className="pz-settings__content">
          {auth.user.vendor === "Panzofi" ? (
            <>
              <h1>{t("others.settings.password.title")}</h1>
              <br />
              <Form onSubmit={submitChangePassword}>
                {({ onChange, data: { introduction } }) => (
                  <React.Fragment>
                    <PasswordField
                      onChange={onChange}
                      label={t("others.settings.password.password")}
                      name="password"
                      validations={[notEmpty, notSpaces, passVal]}
                      required
                    />
                    <PasswordField
                      onChange={onChange}
                      label={t("others.settings.password.password2")}
                      name="re_password"
                      validations={[notEmpty, notSpaces, passVal]}
                      required
                    />
                    <section className="pz-auth-form__buttons">
                      <Button type="submit" primary>
                        {t("others.settings.password.button")}
                      </Button>
                    </section>
                  </React.Fragment>
                )}
              </Form>
            </>
          ) : (
            <div>
              Esta funcionalidad solo esta disponible si te registraste en
              Panzofi
            </div>
          )}
        </section>
      </section>
    </main>
  );
};

export default withRouter(connectReduxReducers(SettingsPasswordPage, "auth"));
