import React from "react";
import { Modal } from "components/common";
import { Button, Form, InputField } from "components/ui";
import { connectReduxReducers } from "hocs";

const ActiveAccountModal = ({ auth, is_visible, closeModal }) => {
  const handleSubmit = (data) => {
    auth.activeAccount(data["code"], (response) => {
      if (response.status === 200) {
        closeModal(true);
      }
    });
  };

  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
    >
      <h1>Activa tu cuenta</h1>
      <p>
        Para activar tu cuenta, utiliza el código de seguridad que recibiste por
        correo electrónico. Si aún no has recibido el correo, por favor contacta
        a <a href="mailto:info@panzofi.com">info@panzofi.com</a>
      </p>
      <Form onSubmit={handleSubmit} className="pz-form">
        {({ onChange }) => (
          <React.Fragment>
            <InputField
              label="Codigo de verificación"
              name="code"
              onChange={onChange}
            />
            <Button type="submit" primary>
              Activar cuenta
            </Button>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(ActiveAccountModal, "auth");
