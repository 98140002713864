import React from "react";
import {
  Button,
  Form,
  Image,
  TextareaField,
  TypingEffectField,
} from "components/ui";
import { connectReduxReducers } from "hocs";
import API from "core/api";
import moment from "moment";
import { renderClasses } from "shared/common/functions";
//import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "shared/common";

const CourseAI = ({ courses, auth }) => {
  //const { t } = useTranslation();
  const [responses, setResponses] = React.useState([]);
  const [nUses, setNUses] = React.useState(10);

  React.useEffect(() => {
    setNUses(getCookie("nUses") ? getCookie("nUses") : 10);
  }, []);

  const handleSubmit = (data) => {
    if (nUses < 1) return;
    const formData = {
      ...data,
    };

    courses.createAIRequest(formData, (response) => {
      if (response.status === 201) {
        setResponses((prevResponses) => [...prevResponses, response.data]);
        if (!!!auth.user?.employee && !!!auth.user?.organization) {
          setCookie("nUses", nUses - 1, 1);
          setNUses(nUses - 1);
        }
      }
    });
  };

  const renderResponse = (responses) =>
    responses.map((actual, key) => {
      return (
        <section className="pz-course-comments__comment" key={key}>
          <section>
            <Image
              className="pz-course-comments__picture"
              name="logos/panzofi.png"
            />
          </section>
          <section className="pz-course-comments__comment--content">
            <h5 className="pz-course-comments__comment--username">
              {"Panzofi"}

              <small>
                {moment(actual.created).format("MMMM D, YYYY - hh:mm a")}
              </small>
            </h5>
            <span className="pz-course-comments__comment--text">
              <TypingEffectField text={actual.response_text} speed={50} />
            </span>
          </section>
        </section>
      );
    });

  return (
    <section className="pz-course-comments">
      <h4 className="bold">PanzofIA</h4>
      <h5>
        <TypingEffectField
          text={"Hola soy PanzofIA, yo te enseñaré lo que quieras"}
          speed={50}
        />
      </h5>
      {API.auth.isLoggedIn() ? (
        <Form
          className="pz-course-comments__form"
          onSubmit={handleSubmit}
          resetOnSubmit
        >
          {({ onChange, data: { user_message } }) => (
            <React.Fragment>
              <TextareaField
                label={`${"Preguntame algo"} (${
                  typeof user_message === "string" ? user_message.length : 0
                }/300) ${
                  !!!auth.user?.employee && !!!auth.user?.organization
                    ? "Te quedan " + nUses + " preguntas"
                    : ""
                }`}
                name="user_message"
                onChange={onChange}
                maxLength={300}
              />
              <Button primary ghost thin>
                Preguntar a panzofi
              </Button>
            </React.Fragment>
          )}
        </Form>
      ) : (
        <h5 className="bold">{"Inicia sesíon para usar Panzofia"}</h5>
      )}
      <section
        className={renderClasses({
          "pz-course-comments__content": true,
        })}
      >
        {responses.length > 0 ? (
          renderResponse(responses)
        ) : (
          <>
            <p>No has preguntado algo</p>
          </>
        )}
      </section>
    </section>
  );
};

CourseAI.defaultProps = {
  commentList: [],
};

export default connectReduxReducers(CourseAI, "courses", "auth");
