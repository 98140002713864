import { LoggedAdminGuard } from "hocs";
import {
  AdminContentPage,
  AdminGeneralPage,
  AdminPanzofiaPage,
  AdminToolPage,
  AdminUsersPage,
} from "./pages";

const Routes = [
  {
    path: "",
    component: LoggedAdminGuard(AdminGeneralPage),
  },
  {
    path: "/users",
    component: LoggedAdminGuard(AdminUsersPage),
  },
  {
    path: "/content",
    component: LoggedAdminGuard(AdminContentPage),
  },
  {
    path: "/tools",
    component: LoggedAdminGuard(AdminToolPage),
  },
  {
    path: "/ais",
    component: LoggedAdminGuard(AdminPanzofiaPage),
  },
];

export default Routes;
