const landing_es = {
  students: {
    title: "Descubre y comparte conocimiento",
    subtitle:
      "Panzofi es una red colaborativa gratuita enfocada en contenido educacional para todos",
    button: "Comienza a aprender",
    carrousel_title: "Panzo contenido popular",
    carrousel_testimony_title: "Testimonios",
    ecosystem: {
      title: "Estamos creando un ecosistema",
      subtitle: "de aprendizaje y enseñanza, donde podrás...",
      item1: {
        title: "Aprender colaborativamente",
        subtitle:
          "En la Panzo-comunidad podrás compartir tus conocimientos, así como adquirir y fortalecer nuevas capacidades con la interacción de otras personas de la red.",
      },
      item2: {
        title: "Encontrar la teoría y práctica en el mismo sitio",
        subtitle:
          "Podrás reforzar la teoría subiendo ejemplos, testimonios, experimentos y mucho más siguiendo el modelo de aprender haciendo (learn by doing).",
      },
      item3: {
        title: "Ahorrar tiempo de búsqueda",
        subtitle:
          "Podrás encontrar más rápido lo que buscabas con la diversidad de lecciones y material que comparte la Panzo-comunidad en diferentes formatos.",
      },
      item4: {
        title: "Economizar tus gastos de crear y de difundir",
        subtitle:
          "No tiene ningún costo de registro ni para subir contenido ni para contribuir. Nuestra meta es hacer más accesible la educación en el mundo.",
      },
    },
    features: {
      title: "Funcionalidades para explorar",
      subtitle: "y que nos diferencia de otras plataformas...",
      item1: {
        title: "Diferentes tipos de contenido",
        subtitle:
          "La Panzo-comunidad puede subir desde .pdf, vídeos y audios o podcast. Usar los formatos más convenientes para tu audiencia o para ti.",
      },
      item2: {
        title: "Intercambiar contenido",
        subtitle:
          "La Panzo-comunidad puede interactuar con el contenido de los creadores con su propio contenido para reforzar la teoría y poner en práctica lo aprendido.",
      },
      item3: {
        title: "Enriquecer tu perfil",
        subtitle:
          "Crea reconocimiento dentro de la Panzo-comunidad, donde podrán conocer tus aportes, tu clasificación, tu puntuaje y muchas más cosas sobre ti.",
      },
      item4: {
        title: "El poder de calificar",
        subtitle:
          "La opinión de la Panzo-comunidad es muy importante. Puedes calificar, comentar y compartir para dar y recibir retroalimentación de valor sobre tu contenido.",
      },
    },
    how_works: {
      title: "¿Cómo funciona?",
      subtitle:
        "Es muy fácil e intuitivo (para más información mira nuestro tutorial haciendo clic aquí)",
      without_acount: {
        title: "Sin cuenta",
        item1: {
          title: "Navegar",
          subtitle: "Ve el cotenido totalmente gratis y sin restricciones",
        },
        item2: {
          title: "Buscar",
          subtitle: "Buscar el contenido de tu interés o un tema en específico",
        },
        item3: {
          title: "Encontrar",
          subtitle: "Encontrar recursos teóricos y prácticos en la red",
        },
        item4: {
          title: "Compartir",
          subtitle:
            "Compartir con tus familiares, amigos y compañeros de estudio",
        },
      },
      with_acount: {
        title: "Con cuenta",
        item1: {
          title: "Calificar",
          subtitle: "Calificar el contenido si es divertido, eficiente y útil",
        },
        item2: {
          title: "Subir",
          subtitle: "Subir contenido teórico y práctico para que todos lo vean",
        },
        item3: {
          title: "Participar",
          subtitle:
            "Participar y colaborar con otros usuarios para aprender mejor",
        },
        item4: {
          title: "Ganar",
          subtitle:
            "Ganar una reputación en la red y mejorar el perfil educativo",
        },
      },
    },
    havent_found: {
      title: "No has encontrado lo que buscabas",
      subtitle:
        "Puedes subir contenido y convertirte en un creador de contenido educacional. ¿Qué estás esperando? haz parte de la Panzo-comunidad.",
      item: {
        title: "Para creadores",
        subtitle:
          "Aporta a la Panzo-comunidad, compartiendo tus habilidades, talentos y conocimientos sobre cualquier tema o interés.",
        subtitle2: "¡Tu imaginación y motivación son tus límites!",
        button: "Más información",
      },
    },
    services: {
      title: "Servicios de Panzofi",
      subtitle:
        "Contamos con servicios para empresas, instituciones y para apoyar a los creadores de contenido",
      item1: {
        title: "Para empresas",
        subtitle:
          "Lleva al siguiente nivel las habilidades de tu equipo de trabajo: motiva, capacita, ahorra tiempo y dinero.",
        subtitle2: "¡Tus empleados te lo agradecerán!",
        button: "Más información",
      },
      item2: {
        title: "Para instituciones educativas",
        subtitle:
          "Únete a la Panzo-comunidad donde tus docentes podrán apoyarse con otros docentes y dedicar más tiempo a sus estudiantes.",
        subtitle2: "¡Tus docentes y estudiantes te lo agradecerán!",
        button: "Más información",
      },
      item3: {
        title: "Servicios para creadores",
        subtitle:
          "Prestamos servicios de marketing, audiovisual, entrenamiento y asesoría para creadores de contenido.",
        subtitle2: "",
        button: "Más información",
      },
    },
    recognitions: {
      title: "Reconocimientos",
      subtitle:
        "Panzofi fue aceptado en el programa de aceleración global AWS EdStart en 2021-2023 para empresas emergentes de tecnología educativa (EdTech).",
      item: {
        title: "AWS EdStart Members",
        subtitle:
          "Apoyo comercial y técnico a las StartUps. Además, ser parte de una gran comunidad internacional de EdTech.",
        bold: "Beneficios",
        subtitle2:
          "Tutorías, participación en redes y eventos, formación técnica, créditos AWS y más",
        subtitle3: "Conoce más del programa",
        bold2: "aquí",
      },
    },
    experts: {
      title: "Conoce a nuestros aliados",
      subtitle: "Creemos en el poder de las alianzas que generan impacto",
      item1: {
        title: "Negu",
        subtitle:
          "Somos una organización que fomenta la innovación en las empresas en cualquiera de sus etapas.",
      },
      item2: {
        title: "Discooperis ",
        subtitle:
          "Compañia de coaching y consultoría. Coaching con perros en Colombia técnica “Master Dog”.",
      },
    },
  },
  teachers: {
    title: "Todos los conocimientos son bienvenidos",
    subtitle:
      "Contribuye al conocimiento colectivo e intercambio de información en la red de Panzofi",
    button: "Únete a nuestra Panzo-comunidad",
    challenges: {
      title: "Los retos",
      subtitle: "de los creadores de contenido educacional son ...",
      item1: {
        title: "Falta de habilidades técnicas",
        subtitle:
          "La creación de contenido educativo puede requerir conocimientos y habilidades en áreas como diseño gráfico, edición de video, programación y producción audiovisual.",
      },
      item2: {
        title: "Limite de tiempo y recursos",
        subtitle:
          "La creación de contenido educativo puede ser un proceso que requiere mucho tiempo y recursos.",
      },
      item3: {
        title: "Alcanzar y mantener audiencia",
        subtitle:
          "Los creadores de contenido educativo deben encontrar formas de llegar a su audiencia y mantener su interés.",
      },
      item4: {
        title: "Calidad de contenido",
        subtitle:
          "Los creadores de contenido educativo deben asegurarse de que su contenido sea de alta calidad, creativo y preciso, para mantener a sus seguidores interesados y atraer nuevos espectadores.",
      },
    },
    tendencies: {
      title: "Las tendencias",
      subtitle: "de los creadores de contenido educacional son...",
      item1: {
        title: "Los creadores del futuro",
        subtitle:
          "Comparten y colaboran con otros para identificar las mejores técnicas de enseñanza y que contenido es el mejor para sus estudiantes.",
      },
      item2: {
        title: "Los creadores del mañana",
        subtitle:
          "Entienden la calificación de la comunidad y generan una participación activa al diseñar nuevos escenarios formativos en la red.",
      },
      item3: {
        title: "Los creadores innovadores",
        subtitle:
          "Emplean estratégicamente la información para disminuir costos de producción al colaborar con la red y dividir cargas de creación digital.",
      },
      item4: {
        title: "Los creadores actuales",
        subtitle:
          "Adquieren una reputación dentro de la comunidad educativa y ganan dinero con respecto a sus seguidores y patrocinadores en un mercado digital.",
      },
    },
    ecosystem: {
      title: "Estamos creando un ecosistema",
      subtitle: "de aprendizaje y enseñanza, donde podrás...",
      item1: {
        title: "Conocer más a tu audiencia",
        subtitle:
          "En Panzofi puedes evidenciar la interacción de tu audiencia con tu contenido. Además, las calificaciones y comentarios te ayudarán a depurar y mejorar tu contenido.",
      },
      item2: {
        title: "Promocionar tu labor",
        subtitle:
          "En Panzofi te podemos ayudar a promocionar tu contenido en nuestra redes sociales y entre la Panzo-comunidad para que crezcas tu audiencia.",
      },
      item3: {
        title: "Diversificar tus canales",
        subtitle:
          "Diversifica tu presencia en espacios digitales con nosotros. Panzofi puede ser otro canal digital para promocionar tus contenidos y darte a conocer en el mundo.",
      },
      item4: {
        title: "Monetizar tu contenido",
        subtitle:
          "Panzofi puede ser otra fuente de ingreso dependiendo del tráfico y participación que generes. Para más información enviar correo a info@panzofi.com",
      },
    },
    how_works: {
      title: "¿Cómo funciona?",
      subtitle: "Es muy fácil e intuitivo sin importar la edad",
      item1: {
        title: "Registrar",
        subtitle: "Resgístrate y crea tu perfil",
      },
      item2: {
        title: "Subir",
        subtitle: "Sube la lección de tu interés o un tema en especifico",
      },
      item3: {
        title: "Compartir",
        subtitle: "Comparte recursos teóricos y prácticos en la red",
      },
      item4: {
        title: "Calificar",
        subtitle: "Comparte, comenta y califica a la comunidad",
      },
    },
    calculator: {
      title: "¿Cuánto Podrías Ganar?",
      select: "Selecciona Tu Categoría :",
      visits: "Visitas Mensuales:",
      earnings: "Ganancias Anuales:",
      categories: {
        admin: "Administración de Empresas",
        arts: "Artes y Humanidades",
        bio: "Biología y ciencias de la vida",
        data: "Ciencias de los datos",
        phisics: "Ciencias Físicas e Ingeniería",
        social: "Ciencias Sociales",
        personal: "Desarrollo personal",
        design: "Diseño",
        lang: "Idiomas",
        it: "Informática y SW",
        math: "Matemáticas y Lógica",
      },
      info: "* No hay garantía ni compromiso de que gane esta cantidad. Las estimaciones se basan en la categoría de contenido que seleccionó. Los ingresos reales dependen de muchos factores, como la demanda de los anunciantes, la ubicación del usuario, el dispositivo del usuario, la vertical del contenido, la estacionalidad, el tamaño del anuncio y los tipos de cambio de moneda.",
    },
    influentecs: {
      title: "Conoce a nuestros influenciadores",
      subtitle:
        "Agradecemos a los creadores por sumar esfuerzos y brindar oportunidades a través de la educación.",
      itemLeft1: {
        title: "CuriosaMente",
        subtitle: "Ciencia y cultura, por el placer de aprender.",
        button: "Ir a su perfil",
      },
      itemLeft2: {
        title: "Ivanevsky",
        subtitle:
          "Ilustrador y Concept Artist. El dibujo y el arte son mi pasión desde siempre.",
        button: "Ir a su perfil",
      },
      itemLeft3: {
        title: "Tonio Cocina",
        subtitle: "Recetas fáciles y deliciosas, para el día a día",
        button: "Ir a su perfil",
      },
      itemLeft4: {
        title: "Gibson Marval",
        subtitle: "Descubre el arte de enseñar con pasión y creatividad",
        button: "Ir a su perfil",
      },
      itemLeft5: {
        title: "Historias para un café",
        subtitle: "Historia, culturas, reinos e imperios.",
        button: "Ir a su perfil",
      },

      itemRight1: {
        title: "Guido Rosas",
        subtitle: "Autor y Consultor especializado en Potencial Humano",
        button: "Ir a su perfil",
      },
      itemRight2: {
        title: "Google Expert",
        subtitle: "Ingeniero de Sistemas, especialista en soluciones de Google",
        button: "Ir a su perfil",
      },
      itemRight3: {
        title: "Juan Ignacio Silva",
        subtitle:
          "Álgebra lineal, cálculo, ecuaciones diferenciales y mucho más.",
        button: "Ir a su perfil",
      },
      itemRight4: {
        title: "Byspel Tech",
        subtitle: "Lenguajes de programación, algoritmos, bases de datos. ",
        button: "Ir a su perfil",
      },
      itemRight5: {
        title: "y más",
        subtitle: "Explora los otros influenciadores de la Panzo-comunidad.",
        button: "Explorar",
      },
    },
  },
  companies: {
    title: "Capacitaciones en línea para empresas",
    subtitle:
      "Tenemos cursos creados para entrenar a tus empleados de una forma eficiente, ágil y divertida",
    button: "",
    courses: {
      title: "Cursos en habilidades blandas y técnicas",
      subtitle:
        "para que tus empleados sean más productivos, motivados y orientados a resultados encontrado un balance entre trabajo y vida personal.",
      item1: {
        title: "Liderazgo",
        subtitle: "Empoderar a tus empleados para que sean líderes.",
      },
      item2: {
        title: "Salud Mental",
        subtitle: "Tus empleados podrán encontrar la paz interior.",
      },
      item3: {
        title: "Destrezas digitales",
        subtitle: "Aumentar la productividad con herramientas tecnológicas.",
      },
      item4: {
        title: "Alimentación saludable",
        subtitle: "Tus empleados estarán más sanos y enérgicos.",
      },
      item5: {
        title: "Trabajo remoto",
        subtitle: "Disminuir riesgo de enfermedades físicas y mentales.",
      },
      item6: {
        title: "Actividad física",
        subtitle: "Tus empleados podrán encontrar la paz interior.",
      },
      item7: {
        title: "Finanzas personales",
        subtitle:
          "Mejores prácticas financieras para el futuro de los empleados.",
      },
      item8: {
        title: "Comunicación corporativa",
        subtitle:
          "Fortalece la cultura corporativa y los resultados de los empleados.",
      },
    },
    free_access: {
      title: "Accede al curso gratuito de meditación",
      subtitle:
        "Yoga y Meditación. Espacio creado para el despertar y el crecimiento personal.",
      item: {
        title: "Ethel Deutsch R.",
        subtitle:
          "Regístrate en el siguiente enlace y recibirás un correo con los siguientes pasos para acceder al curso gratuito (promoción hasta agotar disponibilidad).",
        button: "Más información",
      },
    },
    plans: {
      title: "Planes y precios",
      subtitle: "Escoge el plan que más se acomoda a tu empresa",
      item1: {
        button: "Comprar",
      },
      item2: {
        button: "Comprar",
      },
      item3: {
        button: "Contactar representante",
      },
    },
    how_works: {
      title: "¿Cómo funciona?",
      subtitle: "Es muy fácil sin importar el tamaño de la organización",
      item1: {
        title: "Escoger el plan",
        subtitle: "Llena el formulario y resgístra tu empresa",
      },
      item2: {
        title: "Confirmar el plan",
        subtitle: "Un asesor te estará contactando para adquirir el plan",
      },
      item3: {
        title: "Realizar el pago",
        subtitle: "Dependiendo del plan que escojas debes realizar el pago",
      },
      item4: {
        title: "Activación cuenta",
        subtitle: "Tus empleados tendrán acceso a los cursos",
      },
    },
    cases: {
      title: "Conoce algunos de nuestros casos de éxito",
      subtitle:
        "Más empresas están escogiendo a Panzofi para potencializar el capital humano y obtener mejores resultados.",
      item1: {
        title: "Iscon S.A.S.",
        subtitle:
          "Empresa de urbanización y de construcción que ofrece diferentes servicios en el secto",
      },
      item2: {
        title: "Sanüable",
        subtitle:
          "Proveedores de bienestar a través de productos y servicios que se traducen en felicidad.",
      },
    },
    experts: {
      title: "Conoce algunos de nuestros expertos",
      subtitle:
        "Más de 10 expertos con años de experiencia han generado contenidos valiosos, concretos y cortos para sus empleados.",
      item1: {
        title: "Ethel Deutsch R",
        subtitle:
          "Experta Yoga y Meditación. Guía para el crecimiento personal.",
      },
      item2: {
        title: "Sally Digital",
        subtitle: "Experta en Marketing Digital y en el sector creativo.",
      },
    },
  },
  schools: {
    title: "Descubre y comparte conocimiento",
    subtitle:
      "Panzofi está al alcance de colegios y universidades gratuitamente para contribuir a un red de aprendizaje colaborativa",
    button: "Conoce el contenido de la Panzo-comunidad",
    ecosystem: {
      title: "Estamos creando un ecosistema",
      subtitle:
        "de aprendizaje y enseñanza, donde las instituciones educativas podrán...",
      item1: {
        title: "Aprender colaborativamente",
        subtitle:
          "En la Panzo-comunidad, los profesores y estudiantes podrán compartir todo tipo de conocimientos.",
      },
      item2: {
        title: "Encontrar la teoría y práctica en el mismo sitio",
        subtitle:
          "Los profesores podrán reforzar la teoría con sus estudiantes por medio de contenido prácticos siguiendo el modelo learn by doing.",
      },
      item3: {
        title: "Ahorrar tiempo de búsqueda",
        subtitle:
          "Diferentes insituciones educativas pueden hacer alianzas y coordinar para crear contenido en conjunto.",
      },
      item4: {
        title: "Economizar gastos",
        subtitle:
          "Disminuir costos de producción y creación de contenido digital. A la vez pueden ahorrar costos de desarrollo de la plataforma.",
      },
    },
    brochure: {
      title: "Accede al folleto para instituciones educativas",
      item: {
        title:
          "Haz clic en el siguiente botón para descargar el folleto de Panzofi para universidades y colegios. Puedes encontrar los beneficios, las funcionalidades y un tutorial.",
        button: "Descargar",
      },
    },
    how_works: {
      title: "¿Cómo funciona?",
      subtitle:
        "Es muy fácil e intuitivo (para más información mira nuestro tutorial haciendo clic aquí)",
      without_acount: {
        title: "Sin cuenta",
        item1: {
          title: "Navegar",
          subtitle: "Ve el cotenido totalmente gratis y sin restricciones",
        },
        item2: {
          title: "Buscar",
          subtitle: "Buscar el contenido de tu interés o un tema en específico",
        },
        item3: {
          title: "Encontrar",
          subtitle: "Encontrar recursos teóricos y prácticos en la red",
        },
        item4: {
          title: "Compartir",
          subtitle:
            "Compartir con tus familiares, amigos y compañeros de estudio",
        },
      },
      with_acount: {
        title: "Con cuenta",
        item1: {
          title: "Calificar",
          subtitle: "Calificar el contenido si es divertido, eficiente y útil",
        },
        item2: {
          title: "Subir",
          subtitle: "Subir contenido teórico y práctico para que todos lo vean",
        },
        item3: {
          title: "Participar",
          subtitle:
            "Participar y colaborar con otros usuarios para aprender mejor",
        },
        item4: {
          title: "Ganar",
          subtitle:
            "Ganar una reputación en la red y mejorar el perfil educativo",
        },
      },
    },
    cases: {
      title: "Conoce algunos de nuestros casos de éxito",
      subtitle:
        "Más colegios y universidades están escogiendo a Panzofi para disminuir la brecha de educación y hacer más accesible la misma.",
      item1: {
        title: "Colegio Villa Ricaurte",
        subtitle:
          "Están usando a Panzofi para sus laboratorios y experimientos en física, química y computación.",
      },
      item2: {
        title: "Universidad Ariza",
        subtitle:
          "Están usando a Panzofi para crear comunidades de educación y fomentar el aprendizaje colaborativo.",
      },
    },
  },
  services: {
    title: "Servicios adicionales para creadores",
    subtitle:
      "Servicios de asesoría, entrenamiento, producción audiovisual y marketing para que tus cursos educativos sean más exitosos",
    creators: {
      title: "Los creadores educativos",
      subtitle: "están buscando...",
      item1: {
        title: "Tener más libertad",
        subtitle:
          "Para compartir más tiempo con amigos y familia. Además, obtener libertad financiera.",
      },
      item2: {
        title: "Diversificar sus ingresos",
        subtitle:
          "Comenzar a crear cursos en línea para tener ingresos pasivos.",
      },
      item3: {
        title: "Mejorar el éxito de sus cursos",
        subtitle:
          "Aumentar las ventas y registros de los cursos, obtener mejores comentarios y reseñas.",
      },
      item4: {
        title: "Aumentar su audiencia",
        subtitle: "Crecer la comunidad y mejorar la promoción de sus cursos.",
      },
    },
    inform: {
      title:
        "Accede al informe gratuito sobre ingresos y cifras clave de herramientas educativas",
      item: {
        title:
          "Haz clic en el siguiente botón para descargar el informe de cifras clave de las herramientas educativas más utilizadas por los creadores de contenido. (promoción hasta agotar cupos).",
        button: "Descargar",
      },
    },
    lines: {
      title: "Líneas de servicio de Panzofi",
      subtitle:
        "Dependiendo de la maduración de tu negocio te ofrecemos los servicios que se acomodan mejor a tus necesidades",
      item1: {
        title: "Servicios de asesoría",
        subtitle:
          "Dos paquetes para principiantes y avanzados que te ayudarán a seguir las mejores prácticas del mercado de creadores de contenido educacional.",
        button: "Más información",
      },
      item2: {
        title: "Servicios de entrenamiento",
        subtitle:
          "Como crear un curso en línea exitoso. En este entrenamiento podrás aprender sobre herramientas tecnológicas, promoción, precios y creación de cursos en línea.",
        button: "Más información",
      },
      item3: {
        title: "Servicios de producción audiovisual",
        subtitle:
          "Sabemos que los videos son una gran herramienta en la producción de cursos virtuales, en Panzofi nos especializamos en creación de videos atractivos y memorables.",
        button: "Más información",
      },
      item4: {
        title: "Servicios de marketing",
        subtitle:
          "Para lograr el éxito a largo plazo en el mundo de la educación en línea, es esencial no solo crear un curso de calidad, sino también implementar estrategias efectivas de mercadeo, en Panzofi te ayudamos en la consolidación de estrategias marketing y publicidad.",
        button: "Más información",
      },
    },
  },
  marketing: {
    title: "Servicio de marketing para creadores educativos",
    subtitle:
      "Ofrecemos una amplia gama de servicios de marketing y publicidad para cursos en línea, que te ayudarán a alcanzar tus objetivos de marketing y a tener éxito con tu curso.",
    creators: {
      title: "Nuestros servicios incluyen",
      item1: {
        title: "Estrategia de marketing",
        subtitle:
          "Te ayudamos a definir tus objetivos de marketing y a crear una estrategia para alcanzarlos",
        li1: "Análisis de tu contenido actual y tus métricas de rendimiento.",
        li2: "Investigación de tu audiencia objetivo y sus necesidades.",
        li3: "Identificación de tus competidores y sus estrategias.",
        li4: "Creación de un calendario editorial adaptado a tu audiencia.",
        li5: "Estrategias para fomentar la interacción con tu audiencia.",
        li6: "Clarificación de tus metas a corto, mediano y largo plazo.",
      },
      item2: {
        title: "Análisis de datos",
        subtitle:
          "Te ayudamos a evaluar el rendimiento de tus campañas de marketing",
        li1: "Revisión de tus métricas y rendimiento actuales.",
        li2: "Uso de herramientas avanzadas de análisis para monitorear el rendimiento de tus estrategias.",
        li3: "Generación de informes para evaluar el progreso y ajustar las estrategias según sea necesario.",
        li4: "Uso de datos para mejorar el rendimiento de tus anuncios.",
        li5: "Clarificación de tus metas a corto, mediano y largo plazo.",
      },
      item3: {
        title: "Publicidad",
        subtitle:
          "Te ayudamos a llegar a tu audiencia objetivo con anuncios eficaces",
        li1: "Análisis de tu contenido actual y tus métricas de rendimiento.",
        li2: "Estrategias para mejorar tu posicionamiento en buscadores.",
        li3: "Estrategias avanzadas para aumentar tu visibilidad.",
        li4: "Campañas publicitarias en Facebook, Instagram, LinkedIn y Twitter.",
        li5: "Evaluación constante para identificar áreas de mejora.",
        li6: "Clarificación de tus metas a corto, mediano y largo plazo.",
      },
      item4: {
        title: "Creación de contenido",
        subtitle:
          "Te ayudamos a crear contenido atractivo y relevante para tu audiencia objetivo",
        li1: "Creación de piezas gráficas para plataformas como Instagram, Facebook, LinkedIn y Twitter.",
        li2: "Producción de Reels y videos cortos impactantes y optimizados para Instagram y otras plataformas.",
        li3: "Creación de videos introductorios.",
        li4: "Gráficos personalizados basados en la imagen de marca del creador.",
        li5: "Revisión y ajustes de las piezas gráficas.",
      },
    },
    portfolio: {
      title: "Portafolio",
    },
    testimonies: {
      title: "Testimonios",
      t1: "Panzofi me ayudó con la creación de piezas gráficas para mis redes sociales, cumpliendo con mis expectativas. Su pronta entrega y el diseño de los contenidos fueron de mi completo agrado. Recomiendo sus servicios para las personas que necesiten potenciar sus contenidos y captar la atención de su público objetivo.",
    },
    prices: {
      title: "Precios",
      info1:
        "Estos precios son solo una estimación y pueden variar en función de la complejidad del curso, el número de videos, la duración de los videos y otros factores. Para obtener un presupuesto personalizado, ponte en contacto con nosotros.",
      info2:
        "Si está interesado en crear un curso virtual, contáctenos para obtener más información.",
      table: {
        button: "Contratar Servicio",
        col1: {
          title: "Servicio",
          row1: "Estrategia de marketing",
          row2: "Creación de contenido",
          row3: "Publicidad",
          row4: "Análisis de datos",
        },
        col2: {
          title: "Precio",
          row1: "Desde $500 USD",
          row2: "Desde $100 USD por pieza de contenido",
          row3: "Desde $200 USD por campaña",
          row4: "Desde $200 USD por mes",
        },
      },
      list: {
        l1: "Estrategia de marketing: Este servicio incluye el análisis de la audiencia objetivo, la creación de objetivos y la definición de una estrategia para alcanzarlos.",
        l2: "Creación de contenido: Este servicio incluye la redacción de textos, la creación de imágenes y videos, y el desarrollo de campañas de correo electrónico.",
        l3: "Publicidad: Este servicio incluye la creación y el lanzamiento de anuncios en plataformas como Google, Facebook e Instagram.",
        l4: "Análisis de datos: Este servicio incluye el seguimiento de las métricas clave para evaluar el rendimiento de las campañas de marketing.",
      },
    },
    packages: {
      title: "Creación de contenido: paquetes",
      p1: {
        plan: "Básico",
        price: "$54",
        desc: "3 piezas gráficas para redes sociales (single post, carrete, historias) O video tipo reel (15 -30 segundos)",
        button: "Contratar Servicio",
        time: {
          title: "Tiempo de entrega:",
          desc: "en 3 días",
        },
        revision: {
          title: "Revisiones:",
          desc: "1",
        },
        include: {
          title: "Incluye:",
          li1: "Investigación de 6 palabras clave/hashtags",
          li2: "Gráficos personalizados",
          li3: "Copy out para cada publicación",
        },
        platform: {
          title: "Plataformas:",
        },
      },
      p2: {
        plan: "Estándar",
        price: "$98",
        desc: "3 piezas gráficas para redes sociales (single post, carrete, historias) O video tipo reel (15 -30 segundos) 1 video introductorio para YouTube (15-30 segundos)",
        button: "Contratar Servicio",
        time: {
          title: "Tiempo de entrega:",
          desc: "en 6 días",
        },
        revision: {
          title: "Revisiones:",
          desc: "1",
        },
        include: {
          title: "Incluye:",
          li1: "Investigación de 6 palabras clave/hashtags",
          li2: "Gráficos personalizados",
          li3: "Copy out para cada publicación",
        },
        platform: {
          title: "Plataformas:",
        },
      },
      p3: {
        plan: "Premium",
        price: "$148",
        desc: "3 piezas gráficas para redes sociales (single post, carrete, historias) 1 video tipo reel (15 -30 segundos) 1 video introductorio para YouTube (15-30 segundos)",
        button: "Contratar Servicio",
        time: {
          title: "Tiempo de entrega:",
          desc: "en 10 días",
        },
        revision: {
          title: "Revisiones:",
          desc: "2",
        },
        include: {
          title: "Incluye:",
          li1: "Investigación de 6 palabras clave/hashtags",
          li2: "Gráficos personalizados",
          li3: "Copy out para cada publicación",
        },
        platform: {
          title: "Plataformas:",
        },
      },
    },
  },
  audiovisual: {
    title: "Servicio de producción de videos para cursos en línea",
    subtitle:
      "Creamos ideas innovadoras y producimos videos y audios de alta calidad que impactan y conectan con tu audiencia, para que tus cursos en línea sean exitosos.",
    services: {
      title: "Nuestros servicios incluyen",
      item1: {
        title: "Estrategia y planificación",
        subtitle:
          "Nos encargamos de diseñar una estructura para tu curso, que sea clara y efectiva.",
      },
      item2: {
        title: "Producción",
        subtitle:
          "Grabamos y editamos tus videos de acuerdo con los estándares de calidad más altos.",
      },
      item3: {
        title: "Publicidad",
        subtitle:
          "Te ayudamos a publicar tu curso en las plataformas adecuadas.",
      },
    },
    help: {
      title: "¿Cómo ayudamos a los creadores?",
      item1: {
        title:
          "Si ya tienes el curso estructurado, nos encargamos de la producción de los videos.",
        l1: "Grabamos y editamos los videos de acuerdo con los estándares de calidad más altos.",
        l2: "Te entregamos los videos listos para que los subas a la plataforma que prefieras.",
      },
      item2: {
        title: "Si no tienes el curso estructurado, te ayudamos a diseñarlo.",
        l1: "Te asesoramos para que el curso sea atractivo y genere ventas.",
        l2: "Te ayudamos a organizar tus contenidos y a crear un plan de producción.",
      },
      item3: {
        title: "También te ayudamos a publicar el curso y a promocionarlo.",
        l1: "Publicamos el curso en la plataforma de tu elección.",
        l2: "Te ayudamos a crear una estrategia de marketing para llegar a tu audiencia.",
      },
    },
    prices: {
      title: "Precios",
      info1:
        "Estos precios son solo una estimación y pueden variar en función de la complejidad del curso, el número de videos, la duración de los videos y otros factores. Para obtener un presupuesto personalizado, ponte en contacto con nosotros.",
      info2:
        "Si está interesado en crear un curso virtual, contáctenos para obtener más información.",
      table: {
        button: "Contratar Servicio",
        col1: {
          title: "Servicio",
          row1: "Estrategia y planificación",
          row2: "Producción",
          row3: "Publicación",
        },
        col2: {
          title: "Precio",
          row1: "Desde $500 USD",
          row2: "Desde $1000 USD por video",
          row3: "Desde $200 USD",
        },
      },
      list: {
        l1: "Estrategia y planificación: Este servicio incluye el diseño de una estructura para el curso, la creación de un guion y la selección de los recursos necesarios.",
        l2: "Producción: Este servicio incluye la grabación, la edición y la corrección de los videos.",
        l3: "Publicación: Este servicio incluye la carga de los videos en la plataforma de elección del creador y la configuración de los ajustes técnicos.",
      },
    },
  },
  training: {
    title: "Servicio de entrenamiento para crear cursos en línea",
    subtitle:
      "Ofrecemos nuestro programa entrenamiento para que tus cursos educativos sean más exitosos, alcancen un posicionamiento digital y generes nuevas fuentes de ingresos.",
    learn: {
      title: "Lo que aprenderás",
      item1: {
        title: "Planificación",
        subtitle:
          "Te enseñamos las mejores prácticas para planificar la creación de tu curso en línea.",
      },
      item2: {
        title: "Herramientas y equipos",
        subtitle:
          "Aprenderás los requerimientos de equipos y aplicaciones para la creación de cursos.",
      },
      item3: {
        title: "Creación y diseño",
        subtitle:
          "Te enseñamos a usar las herramientas más populares en la creación de cursos en línea atractivos.",
      },
      item4: {
        title: "Publicación y promoción",
        subtitle:
          "Te enseñamos diferentes estrategias para lanzar y promocionar tu curso exitosamente.",
      },
    },
    content: {
      title: "Contenido del curso",
      subtitle: "12 Modulos | 78 clases",
      course: {
        module1: {
          title: "Introducción",
          description: "4 clases",
          module1content1: {
            title: "Bienvenida",
            description: "",
          },
          module1content2: {
            title:
              "Cómo crear y vender cursos en línea: estrategias para el éxito",
            description: "",
          },
          module1content3: {
            title: "¡Crea tu primera clase ahora!",
            description: "",
          },
          module1content4: {
            title: "¡Crea y vende tu primer curso en línea con éxito!",
            description: "",
          },
        },
        module2: {
          title: "Hardware, Software y requerimientos",
          description: "4 clases",
          module2content1: {
            title: "Introducción",
            description: "",
          },
          module2content2: {
            title:
              "Equipos y herramientas para crear cursos en línea de calidad",
            description: "",
          },
          module2content3: {
            title:
              "Herramientas para crear presentaciones y grabar diapositivas",
            description: "",
          },
          module2content4: {
            title:
              "Cómo crear un curso en línea exitoso: requisitos y consejos",
            description: "",
          },
        },
        module3: {
          title: "Economía",
          description: "5 clases",
          module3content1: {
            title: "Introducción",
            description: "",
          },
          module3content2: {
            title: "Modulo de costos",
            description: "",
          },
          module3content3: {
            title: "Diversificación de fuentes de ingresos",
            description: "",
          },
          module3content4: {
            title: "Escalabilidad en los negocios de cursos en línea",
            description: "",
          },
          module3content5: {
            title: "Destacando en el mundo competitivo de los cursos en línea",
            description: "",
          },
        },
        module4: {
          title: "Validación del curso",
          description: "5 clases",
          module4content1: {
            title: "Introducción",
            description: "",
          },
          module4content2: {
            title: "Cómo encontrar pruebas de mercado",
            description: "",
          },
          module4content3: {
            title: "Estructurando un curso atractivo",
            description: "",
          },
          module4content4: {
            title: "Obteniendo retroalimentación para crear tu curso",
            description: "",
          },
          module4content5: {
            title: "Preventa exitosa: vende tu curso antes de crearlo",
            description: "",
          },
        },
        module5: {
          title: "Creando un curso increíble",
          description: "4 clases",
          module5content1: {
            title: "Introducción",
            description: "",
          },
          module5content2: {
            title: "Estilos de curso en línea",
            description: "",
          },
          module5content3: {
            title: "Creación de comunidad",
            description: "",
          },
          module5content4: {
            title: "Consejos",
            description: "",
          },
        },
        module6: {
          title: "Creando tu primera clase",
          description: "7 clases",
          module6content1: {
            title: "Introducción",
            description: "",
          },
          module6content2: {
            title: "Preparación y Diseño de Presentaciones Impactantes",
            description: "",
          },
          module6content3: {
            title: "Iniciando con powerpoint",
            description: "",
          },
          module6content4: {
            title: "Iniciando con Google Slides",
            description: "",
          },
          module6content5: {
            title: "Iniciando con Keynote",
            description: "",
          },
          module6content6: {
            title: "Iniciando con Canva",
            description: "",
          },
          module6content7: {
            title:
              "Recursos para Diseñar Presentaciones Visualmente Atractivas",
            description: "",
          },
        },
        module7: {
          title: "Armar el curso",
          description: "3 clases",
          module7content1: {
            title: "Poniendo el curso en marcha",
            description: "",
          },
          module7content2: {
            title: "Creando una introducción impactante para tu curso en línea",
            description: "",
          },
          module7content3: {
            title:
              "Rompiéndolo en pedazos: la importancia de descomponer tu curso",
            description: "",
          },
        },
        module8: {
          title: "Grabación de tu curso",
          description: "4 clases",
          module8content1: {
            title: "Grabación de tu curso: consejos para un video profesional",
            description: "",
          },
          module8content2: {
            title: "Elige un estilo de grabación para tu conferencia",
            description: "",
          },
          module8content3: {
            title: "Cómo grabar la conferencia perfecta",
            description: "",
          },
          module8content4: {
            title: "Conecta Powerpoint con Camtasia",
            description: "",
          },
        },
        module9: {
          title: "Hospedaje de tu curso",
          description: "12 clases",
          module9content1: {
            title: "Introducción",
            description: "",
          },
          module9content2: {
            title: "Alojamiento propio vs. Plataformas de mercado",
            description: "",
          },
          module9content3: {
            title: "¿Cuál opción de auto hospedaje deberías elegir?",
            description: "",
          },
          module9content4: {
            title: "Comenzando con thinkific: tu plataforma para cursos online",
            description: "",
          },
          module9content5: {
            title:
              "Empezando con Teachable: tu plataforma para cursos en línea",
            description: "",
          },
          module9content6: {
            title: "Comienza con Podia: tu plataforma para crecer en línea",
            description: "",
          },
          module9content7: {
            title:
              "Comienza rápidamente con Learnworlds: tu plataforma de cursos en línea",
            description: "",
          },
          module9content8: {
            title: "Los mejores plugins de Wordpress para cursos en línea",
            description: "",
          },
          module9content9: {
            title: "Explorando los mercados de cursos en línea",
            description: "",
          },
          module9content10: {
            title: "Cómo crear tu curso en Udemy",
            description: "",
          },
          module9content11: {
            title: "Cómo subir tu curso a Skillshare",
            description: "",
          },
          module9content12: {
            title: "Escribe mejores títulos y descripciones que venden",
            description: "",
          },
        },
        module10: {
          title: "Promoción de tu curso",
          description: "14 clases",
          module10content1: {
            title: "Introducción",
            description: "",
          },
          module10content2: {
            title:
              "Identificando tu propuesta única de venta (usp) y audiencia objetivo",
            description: "",
          },
          module10content3: {
            title:
              "Explotando el poder de las redes sociales para promocionar tu curso",
            description: "",
          },
          module10content4: {
            title:
              "Aprovecha el potencial del seo para promocionar tus cursos en línea",
            description: "",
          },
          module10content5: {
            title:
              "Cómo crear un video promocional de Youtube exitoso para tu curso online",
            description: "",
          },
          module10content6: {
            title:
              "Cómo crear un video promocional exitoso para tu curso en Panzofi",
            description: "",
          },
          module10content7: {
            title:
              "Cómo desarrollar un curso mini complementario para impulsar tus ventas",
            description: "",
          },
          module10content8: {
            title:
              "Cómo ofrecer descuentos en tus cursos para impulsar las ventas",
            description: "",
          },
          module10content9: {
            title:
              "Cómo reunir reseñas de tus estudiantes actuales para mejorar tu curso",
            description: "",
          },
          module10content10: {
            title: "Cómo promocionar efectivamente tu curso online",
            description: "",
          },
          module10content11: {
            title:
              "Aprovechando el poder de la publicidad en línea para tu curso",
            description: "",
          },
          module10content12: {
            title:
              "Aprovechando el poder de las reseñas y editores para tu curso",
            description: "",
          },
          module10content13: {
            title:
              "Cómo ser un invitado de podcast exitoso para promocionar tu curso online",
            description: "",
          },
          module10content14: {
            title:
              "Cómo crear y presentar un seminario web en vivo atractivo para promocionar tu curso online",
            description: "",
          },
        },
        module11: {
          title: "Vender tu curso",
          description: "10 clases",
          module11content1: {
            title: "Introducción",
            description: "",
          },
          module11content2: {
            title:
              "¿Qué es un embudo de ventas? Guía para dirigir a los clientes hacia la compra",
            description: "",
          },
          module11content3: {
            title: "Cómo construir el embudo de ventas perfecto en YouTube",
            description: "",
          },
          module11content4: {
            title: "Cómo crear un embudo de correo electrónico efectivo",
            description: "",
          },
          module11content5: {
            title: "Cómo crear un embudo de ventas automatizado",
            description: "",
          },
          module11content6: {
            title: "Cómo crear un formulario con Google Forms",
            description: "",
          },
          module11content7: {
            title: "Guía para comenzar con MailChimp",
            description: "",
          },
          module11content8: {
            title: "Cómo comenzar con un crm para vender tus cursos en línea",
            description: "",
          },
          module11content9: {
            title: "¿Cómo determinar el precio de mi curso online?",
            description: "",
          },
          module11content10: {
            title: "¿Qué impulsa las compras de los consumidores?",
            description: "",
          },
        },
        module12: {
          title: "Haz crecer tu marca y tus ingresos",
          description: "6 clases",
          module12content1: {
            title: "Introducción",
            description: "",
          },
          module12content2: {
            title: "Escalabilidad en la venta de cursos en línea",
            description: "",
          },
          module12content3: {
            title: "Ser el primer jugador en la venta de cursos en línea",
            description: "",
          },
          module12content4: {
            title: "Mejora tu estrategia de venta cruzada",
            description: "",
          },
          module12content5: {
            title:
              "Optimiza tu negocio de cursos en línea con retroalimentación",
            description: "",
          },
          module12content6: {
            title: "Errores comunes en la creación de cursos en línea",
            description: "",
          },
        },
      },
    },
    why_course: {
      title: "¿Por qué tomar este curso con Panzofi?",
      item1: {
        title:
          "Te ayudamos a darle forma a tu idea, a encontrar el nicho de mercado y a definir tu audiencia y público objetivo.",
      },
      item2: {
        title:
          "Te enseñamos a estructurar tu curso, buscando siempre la sencillez y efectividad del contenido que deseas mostrar.",
      },
      item3: {
        title:
          "Te ayudamos a definir el precio ideal para tu curso, basados en estrategias clave y precios competitivos del mercado.",
      },
      item4: {
        title:
          "Te enseñamos estrategias exitosas de promoción y de gestión de tus estudiantes.",
      },
    },
    prices: {
      title: "Precios",
      info1:
        "Si estás interesado en recibir nuestros servicios de entrenamiento, contáctanos para obtener más información.",
      table: {
        button: "Contratar Servicio",
        col1: {
          title: "Servicio",
          row1: "Curso de entrenamiento",
        },
        col2: {
          title: "Precio",
          row1: "Dto",
        },
      },
      list: {
        title:
          "El programa de entrenamiento para crear cursos en línea incluye:",
        l1: "12 módulos",
        l2: "78 lecciones",
        l3: "Acceso de inmediato a todos los contenidos",
        l4: "Acceso de por vida a futuras actualizaciones",
        l5: "Soporte rápido y útil en la sección de preguntas y respuestas",
      },
    },
  },
  advisory: {
    title: "Asesorías y capacitaciones para creadores",
    subtitle:
      "Te ofrecemos nuestro programa de asesoría y entrenamiento para que tus cursos educativos sean más exitosos",
    button: "Solicita ser contactado",
    service: {
      title: "¿Qué aprenderás del servicio?",
      item1: {
        title: "Incrementar tus ventas de cursos en línea",
      },
      item2: {
        title: "Aprender a crear cursos potenciales",
      },
      item3: {
        title: "Validar la idea o temática del curso",
      },
      item4: {
        title: "Aprender a promocionar tus cursos efectivamente",
      },
      item5: {
        title: "Construir ventas automatizadas",
      },
      item6: {
        title: "Diseñar cursos de alta calidad",
      },
      item7: {
        title: "Aprende a crear y crecer tu audiencia",
      },
      item8: {
        title: "Tips para crear cursos más exitosos",
      },
    },
    starter: {
      title: "Asesoría y entrenamiento (starter)",
      item1: {
        title: "¿A quién va dirigido?",
        subtitle:
          "Personas en distintas áreas del conocimiento, que deseen crear contenidos educativos en línea o deseen mejorar sus resultados.",
      },
      item2: {
        title: "Duración",
        subtitle:
          "Asesoría de 9 sesiones de 2 horas (cada una), para un total de 18 horas acompañando tu proceso.",
        subtitle2:
          "Además, de capacitaciones en linea en el transcurso de la asesoría.",
      },
      item3: {
        title: "Cupos",
        subtitle:
          "30 cupos por curso. Si los participantes no están completos, el curso comenzará en la siguiente fecha programada.",
      },
      item4: {
        title: "Fechas",
        subtitle:
          "El curso comienza la primera semana de cada mes. Contra registro, se confirmará la fecha de ingreso.",
        subtitle2: "Para saber más, enviar correo a info@panzofi.com",
      },
    },
    level: {
      title: "Alcanza el siguiente nivel",
      subtitle:
        "LLevas más de un año creando contenido y quieres accelerar el proceso con herramientas de tecnología para disminuir costos de producción y automatizar tus ventas para mejorar tus resultados",
    },
    advance: {
      title: "Asesoría y entrenamiento (advanced)",
      item1: {
        title: "¿A quién va dirigido?",
        subtitle:
          "Creadores de contenido con más de un año de experiencia y que quieran potencializar sus resultados (ventas, producción, etc).",
      },
      item2: {
        title: "Duración",
        subtitle:
          "Asesoría de 9 sesiones de 2 horas (cada una), para un total de 18 horas acompañando tu proceso.",
        subtitle2:
          "Además, de capacitaciones en linea en el transcurso de la asesoría.",
      },
      item3: {
        title: "Cupos",
        subtitle:
          "30 cupos por curso. Si los participantes no están completos, el curso comenzará en la siguiente fecha programada.",
      },
      item4: {
        title: "Fechas",
        subtitle:
          "El curso se realiza cada dos meses (los meses pares). Contra registro, se confirmará la fecha de ingreso.",
        subtitle2: "Para saber más, enviar correo a info@panzofi.com",
      },
    },
    plans: {
      title: "Plan y precios",
      subtitle: "Escoge el plan que más se acomode a ti",
      item1: {
        button: "Comprar",
      },
      item2: {
        button: "Más información",
      },
    },
    how_works: {
      title: "¿Cómo funciona?",
      subtitle: "Es muy fácil, sigue los siguientes pasos...",
      item1: {
        title: "Escoger el plan",
        subtitle: "Un asesor te estará contactando para adquirir el plan",
      },
      item2: {
        title: "Realizar el pago",
        subtitle: "Dependiendo del plan que escojas debes realizar el pago",
      },
      item3: {
        title: "Activación cuenta",
        subtitle: "Se envía correo con acceso a los cursos",
      },
      item4: {
        title: "Comienzo del curso",
        subtitle: "Se envía correo con las sesiones programadas del mes",
      },
    },
    cases: {
      title: "Conoce algunos de nuestros casos de éxito",
      subtitle:
        "Más creadores de contenido están escogiendo a Panzofi para aumentar sus ingresos y consolidar su comunidad.",
      item1: {
        title: "Camila Prieto (29 años)",
        subtitle:
          "Con las asesorías de Panzofi, logré crear mi curso de metodologías ágiles en diferentes plataformas. Logré posicionar mi marca como consultora y también logré adquirir experiencia en la creación de contenido.",
        subtitle2:
          "Mis ingresos incrementaron un 36% y la verdad estoy muy contenta.",
      },
      item2: {
        title: "Juan Robles (35 años)",
        subtitle:
          "Me gustaron mucho las asesorías. Pude ponerme en los zapatos de mis estudiantes y pude conectar mejor con ellos. También, me ayudaron a conocer más herramientas tecnológicas para ser más éxitoso y liberarme de carga operativa.",
        subtitle2: "He lanzado más cursos con una mayor tasa de registros.",
      },
    },
  },
};
export default landing_es;
