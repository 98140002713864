import React from "react";
import {
  CarouseRecent,
  CarouselRecommended,
  Footer,
  Navbar,
} from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { Page } from "components/common";
import { Button, Image } from "components/ui";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { CourseCard } from "apps/courses/components";
import { useTranslation } from "react-i18next";
import { CONSTANTS } from "shared/constants";
import { Link } from "react-router-dom/cjs/react-router-dom";

const CoursesPage = ({ courses: reduxCourses, location, history, auth }) => {
  const [courses, setCourses] = React.useState([]);
  const [limit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const [moreCourses, setMoreCourses] = React.useState(true);
  const { t } = useTranslation();

  React.useEffect(() => {
    getMoreCourses();
    // eslint-disable-next-line
  }, []);

  const getMoreCourses = () => {
    const params = {
      search: getSearchParams()?.search,
      category__slug: getSearchParams()?.category,
      limit: limit,
      offset: offset,
      ordering: "-num_reaction",
    };
    reduxCourses.getCourses(params, ({ data, status }) => {
      const sortedCourses =
        status === 200 ? [...courses, ...data.results] : null;
      setCourses(sortedCourses);
      setOffset(offset + limit);
      data.next === null ? setMoreCourses(false) : setMoreCourses(true);
    });
  };

  const renderCoursesList = (courses) => {
    return (
      <>
        {courses.map((course, key) => (
          <CourseCard course={course} key={key} />
        ))}
      </>
    );
  };

  const getSearchParams = () => queryString.parse(location.search);

  if (auth.user?.employee) {
    return <></>;
  } else if (auth.user?.organization) {
    return <></>;
  } else if (courses) {
    return (
      <main className="pz-courses">
        <Navbar defaultSearchValue={getSearchParams()?.search} />
        <section className="pz-posts__head">
          <Link
            to={CONSTANTS.URLS.COURSES.ALL}
            className="pz-posts__head--item bold pz-posts__head--active"
          >
            {t("courses.bar.lessons")}
          </Link>
          |
          <Link
            to={CONSTANTS.URLS.COURSES.POSTS}
            className="pz-posts__head--item bold"
          >
            {t("courses.bar.shorts")}
          </Link>
        </section>
        <Page className="pz-courses__container">
          <CarouselRecommended
            coursesdomi={null}
            autoPlay={false}
            title={"Cursos recomendados"}
            fullPage={true}
          />
          <CarouseRecent
            coursesdomi={null}
            autoPlay={false}
            title={t("courses.recent")}
            fullPage={true}
          />

          <section className="pz-courses__courses__tabs">
            <h2>{t("courses.content")}</h2>
          </section>

          <section className="pz-courses__courses">
            {renderCoursesList(courses)}
          </section>
          <section className="pz-courses__load__button">
            {moreCourses ? (
              <Button primary thin onClick={getMoreCourses}>
                {t("courses.more_courses")}
              </Button>
            ) : (
              <Button disabled thin>
                {t("courses.no_more_courses")}
              </Button>
            )}
          </section>
        </Page>

        <Footer />
      </main>
    );
  } else if (courses === null) {
    return (
      <main className="pz-profile">
        <Navbar />
        <Page className="pz-profile__404">
          <h1 className="text-center">{t("notFound.no_resource")}</h1>
          <Image name="ilustrations/404.svg" />
        </Page>
        <Footer />
      </main>
    );
  }

  return (
    <main className="pz-profile">
      <Navbar />
    </main>
  );
};

export default connectReduxReducers(withRouter(CoursesPage), "courses");
