import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { Page } from "components/common";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const LandingCompaniesPage = ({ auth, history }) => {
  const { t } = useTranslation();
  const goToSignupCompany = () => {
    history.push(CONSTANTS.URLS.AUTH.SIGNUP_COMPANY);
  };
  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <section className="pz-landing__cover">
          <section className="pz-landing__cover--content animate__animated animate__bounceIn">
            <Image
              name="ilustrations/interact-network.svg"
              className="pz-landing__cover--image"
            />
            <h1 className="text-center bold">{t("landing.companies.title")}</h1>
            <p>{t("landing.companies.subtitle")}</p>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.courses.title")}</h2>
            <p>{t("landing.companies.courses.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/grow-teachers.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item1.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/metal_health.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item2.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item2.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/value_content.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item3.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/healthy_options.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item4.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item4.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/remotely.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item5.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item5.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/working_out.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item6.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item6.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/benefits.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item7.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item7.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/corporate_comunication.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.courses.item8.title")}
                  </h4>
                  <p>{t("landing.companies.courses.item8.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.free_access.title")}</h2>
            <p>{t("landing.companies.free_access.subtitle")}</p>
          </section>

          <section className="pz-landing__services">
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/meditation-promo.png"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.companies.free_access.item.title")}
                </h3>
                <p>{t("landing.companies.free_access.item.subtitle")}</p>
                <Button primary ghost thin>
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={
                      "https://share.hsforms.com/1esGnDQXYRPeoaiw27yr8OQbptug"
                    }
                  >
                    {t("landing.companies.free_access.item.button")}
                  </a>
                </Button>
              </section>
            </article>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.plans.title")}</h2>
            <p>{t("landing.companies.plans.subtitle")}</p>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--seconditem">
                <Image
                  name="ilustrations/plan1.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </article>

              <Button primary ghost thin onClick={goToSignupCompany}>
                {t("landing.companies.plans.item1.button")}
              </Button>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--seconditem">
                <Image
                  name="ilustrations/plan2.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </article>
              <Button primary ghost thin onClick={goToSignupCompany}>
                {t("landing.companies.plans.item2.button")}
              </Button>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--seconditem">
                <Image
                  name="ilustrations/plan3.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </article>
              <Button primary ghost thin onClick={goToSignupCompany}>
                {t("landing.companies.plans.item3.button")}
              </Button>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.how_works.title")}</h2>
            <p>{t("landing.companies.how_works.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/navigate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.how_works.item1.title")}
                  </h4>
                  <p>{t("landing.companies.how_works.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/planning.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.how_works.item2.title")}
                  </h4>
                  <p>{t("landing.companies.how_works.item2.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/debit-card.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.how_works.item3.title")}
                  </h4>
                  <p>{t("landing.companies.how_works.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item column">
                <Image
                  name="icons/switch.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.how_works.item4.title")}
                  </h4>
                  <p>{t("landing.companies.how_works.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.cases.title")}</h2>
            <p>{t("landing.companies.cases.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/client1.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.cases.item1.title")}
                  </h4>
                  <p>{t("landing.companies.cases.item1.subtitle")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/client2.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.cases.item2.title")}
                  </h4>
                  <p>{t("landing.companies.cases.item2.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.companies.experts.title")}</h2>
            <p>{t("landing.companies.experts.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/referencia-e2.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.experts.item1.title")}
                  </h4>
                  <p>{t("landing.companies.experts.item1.subtitle")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/referencia-sally.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.companies.experts.item2.title")}
                  </h4>
                  <p>{t("landing.companies.experts.item2.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default withRouter(LandingCompaniesPage);
