import { CoursePage, CoursesAll, CoursesPage, LessonPage } from "./pages";
import { LoggedNoB2BGuard, OptionalLoggedGuard } from "hocs/guards";

const Routes = [
  {
    path: "",
    component: LoggedNoB2BGuard(CoursesPage),
  },
  {
    path: "/all",
    component: OptionalLoggedGuard(CoursesAll),
  },
  {
    path: "/:courseSlug",
    component: OptionalLoggedGuard(CoursePage),
  },
  {
    path: "/:courseSlug/:moduleId/:lessonId",
    component: OptionalLoggedGuard(LessonPage),
  },
];

export default Routes;
