import React from "react";
import { Navbar, NavbarConfig } from "components/panzofi";
import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";
import { ConfirmationDeleteAccountModal } from "apps/profile/components";

const SettingsDeletePage = ({ auth, profile }) => {
  const [deleteAcountModalVisible, setDeleteAcountModalVisible] =
    React.useState(false);
  const { t } = useTranslation();
  const closeDeleteConfirmation = () => {
    setDeleteAcountModalVisible(false);
  };
  if (auth.user?.organization)
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          No tienes permiso para realizar esta acción.
        </section>
      </main>
    );
  else if (auth.user?.employee)
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          No tienes permiso para realizar esta acción.
        </section>
      </main>
    );
  else
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          <NavbarConfig index={3} />
          <section className="pz-settings__content">
            <h1>{t("others.settings.delete.title")}</h1>
            <Button alert onClick={() => setDeleteAcountModalVisible(true)}>
              {t("others.settings.delete.button")}
            </Button>
          </section>
        </section>
        <ConfirmationDeleteAccountModal
          is_visible={deleteAcountModalVisible}
          closeModal={closeDeleteConfirmation}
        />
      </main>
    );
};

export default connectReduxReducers(SettingsDeletePage, "auth", "profile");
