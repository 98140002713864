import React from "react";
import { Button, Image, TypingEffectField, UsernameBadge } from "components/ui";
import { Link, withRouter } from "react-router-dom";
import SlideToggle from "react-slide-toggle";
import eases from "eases";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs";
import API from "core/api";
import { renderClasses } from "shared/common/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faBullhorn,
  faGraduationCap,
  faHome,
  faLightbulb,
  faSignOutAlt,
  faTv,
  faUser,
  faUsers,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

import NavbarLoginForm from "components/panzofi/navbar/login-form";
import queryString from "query-string";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import categories from "./categories";
import Dropdown from "react-multilevel-dropdown";
import { useTranslation } from "react-i18next";
import { ES, US } from "country-flag-icons/react/3x2";
import NewsBanner from "../news-banner";

const Navbar = ({ auth, children, history, location, defaultSearchValue }) => {
  const [searchValue, setSearchValue] = React.useState(defaultSearchValue);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setSearchValue(defaultSearchValue);
  }, [defaultSearchValue]);

  const currentLang = () => {
    switch (i18n.language) {
      case "es":
        return <ES />;
      case "en":
        return <US />;

      default:
        return <ES />;
    }
  };
  const menuOptionsProfile =
    !!!auth.user?.employee && !!!auth.user?.organization
      ? [
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faUser} />,
            text: t("navbar.dropdown.profile"),
            action: () => history.push(CONSTANTS.URLS.PROFILE.USER_LOGGED),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faHome} />,
            text: t("navbar.dropdown.home"),
            action: () => history.push(CONSTANTS.URLS.LANDING.HOME),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faBullhorn} />,
            text: "Foros",
            action: () => history.push(CONSTANTS.URLS.FORUM.ALL),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faTv} />,
            text: t("navbar.dropdown.courses"),
            action: () => history.push(CONSTANTS.URLS.COURSES.ALL),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faUsers} />,
            text: t("navbar.dropdown.creators"),
            action: () => history.push(CONSTANTS.URLS.LANDING.TEACHERS),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faBuilding} />,
            text: t("navbar.dropdown.companies"),
            action: () => history.push(CONSTANTS.URLS.LANDING.COMPANIES),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faGraduationCap} />,
            text: t("navbar.dropdown.schools"),
            action: () => history.push(CONSTANTS.URLS.LANDING.UNIVERSITIES),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faLightbulb} />,
            text: t("navbar.dropdown.asesories"),
            action: () => history.push(CONSTANTS.URLS.LANDING.CONSULTANT),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faTiktok} />,
            text: t("navbar.dropdown.socials"),
            action: () => {
              window.open("https://beacons.ai/panzofi", "_blank");
            },
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faWrench} />,
            text: "Configuración",
            action: () =>
              history.push(
                CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS(auth.user?.slug)
              ),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faSignOutAlt} />,
            text: t("navbar.dropdown.loguot"),
            action: () => auth.logout(),
          },
        ]
      : [
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faUser} />,
            text: t("navbar.dropdown.profile"),
            action: () => history.push(CONSTANTS.URLS.PROFILE.USER_LOGGED),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faHome} />,
            text: t("navbar.dropdown.home"),
            action: () => history.push(CONSTANTS.URLS.LANDING.HOME),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faBuilding} />,
            text: t("navbar.dropdown.companies"),
            action: () => history.push(CONSTANTS.URLS.LANDING.COMPANIES),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faTiktok} />,
            text: t("navbar.dropdown.socials"),
            action: () => {
              window.open("https://beacons.ai/panzofi", "_blank");
            },
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faWrench} />,
            text: "Configuración",
            action: () =>
              history.push(
                CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS_PASSWORD(
                  auth.user?.slug
                )
              ),
          },
          {
            type: "option",
            icon: <FontAwesomeIcon icon={faSignOutAlt} />,
            text: t("navbar.dropdown.loguot"),
            action: () => auth.logout(),
          },
        ];

  const menuOptionsProfileMobile = [
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faHome} />,
      text: t("navbar.dropdown.profile"),
      action: () => history.push(CONSTANTS.URLS.PROFILE.USER_LOGGED),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faTiktok} />,
      text: t("navbar.dropdown.socials"),
      action: () => {
        window.open("https://beacons.ai/panzofi", "_blank");
      },
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faWrench} />,
      text: "Configuración",
      action: () =>
        history.push(
          !!!auth.user?.employee && !!!auth.user?.organization
            ? CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS(auth.user?.slug)
            : CONSTANTS.URLS.PROFILE.USER_DETAIL_SETTINGS_PASSWORD(
                auth.user?.slug
              )
        ),
    },
    {
      type: "option",
      icon: <FontAwesomeIcon icon={faSignOutAlt} />,
      text: t("navbar.dropdown.loguot"),
      action: () => auth.logout(),
    },
  ];

  const menuOptionsMobile =
    !!!auth.user?.employee && !!!auth.user?.organization
      ? [
          {
            type: API.auth.isLoggedIn() ? "" : "button",
            icon: {},
            text: (
              <Button primary thin>
                {t("navbar.login")}
              </Button>
            ),
            action: () => history.push(CONSTANTS.URLS.AUTH.LOGIN),
          },
          {
            type: API.auth.isLoggedIn() ? "" : "button",
            icon: {},
            text: (
              <Button primary ghost thin>
                {t("navbar.signup")}
              </Button>
            ),
            action: () => history.push(CONSTANTS.URLS.AUTH.SIGNUP),
          },
          {
            type: "line",
            icon: {},
            text: {},
            action: () => {},
          },
          {
            type: "title",
            icon: {},
            text: t("navbar.dropdown.all_courses"),
            action: () => history.push(CONSTANTS.URLS.COURSES.ALL),
          },
          {
            type: "title",
            icon: {},
            text: t("courses.bar.shorts"),
            action: () => history.push(CONSTANTS.URLS.COURSES.POSTS),
          },
          {
            type: "title",
            icon: {},
            text: "Foros",
            action: () => history.push(CONSTANTS.URLS.FORUM.ALL),
          },
          {
            type: "title",
            icon: {},
            text: t("navbar.dropdown.popular_categories"),
            action: () => {},
          },
          {
            type: "option",
            icon: {},
            text: t(
              `categories.ciencias-sociales.sub_categories.administracion-de-empresas.name`
            ),
            action: () => {
              history.push(
                `${
                  CONSTANTS.URLS.COURSES.ALLPage
                }?category=${"administracion-de-empresas"}`
              );
            },
          },
          {
            type: "option",
            icon: {},
            text: t(
              `categories.ingenieria.sub_categories.informatica-y-software.name`
            ),
            action: () => {
              history.push(
                `${
                  CONSTANTS.URLS.COURSES.ALLPage
                }?category=${"informatica-y-software"}`
              );
            },
          },
          {
            type: "option",
            icon: {},
            text: t(`categories.gastronomia.sub_categories.comidas.name`),
            action: () => {
              history.push(
                `${CONSTANTS.URLS.COURSES.ALLPage}?category=${"comidas"}`
              );
            },
          },
          {
            type: "option",
            icon: {},
            text: t(
              `categories.ciencias-fisicas-y-naturales.sub_categories.biologia.name`
            ),
            action: () => {
              history.push(
                `${CONSTANTS.URLS.COURSES.ALLPage}?category=${"biologia"}`
              );
            },
          },
          {
            type: "option",
            icon: {},
            text: t(
              `categories.ciencias-sociales.sub_categories.psicologia.name`
            ),
            action: () => {
              history.push(
                `${CONSTANTS.URLS.COURSES.ALLPage}?category=${"psicologia"}`
              );
            },
          },
          {
            type: "option",
            icon: {},
            text: t(
              `categories.ciencias-sociales.sub_categories.historia.name`
            ),
            action: () => {
              history.push(
                `${CONSTANTS.URLS.COURSES.ALLPage}?category=${"historia"}`
              );
            },
          },
          {
            type: "line",
            icon: {},
            text: {},
            action: () => {},
          },
          {
            type: "title",
            icon: {},
            text: t("navbar.dropdown.other_services"),
            action: () => {},
          },
          {
            type: "option",
            icon: {},
            text: t("navbar.dropdown.companies"),
            action: () => {
              history.push(CONSTANTS.URLS.LANDING.COMPANIES);
            },
          },
          {
            type: "option",
            icon: {},
            text: t("navbar.dropdown.schools"),
            action: () => {
              history.push(CONSTANTS.URLS.LANDING.UNIVERSITIES);
            },
          },
          {
            type: "option",
            icon: {},
            text: t("navbar.dropdown.services"),
            action: () => {
              history.push(CONSTANTS.URLS.LANDING.SERVICES);
            },
          },
          {
            type: "line",
            icon: {},
            text: t("navbar.dropdown.schools"),
            action: () => {},
          },
          {
            type: "lang",
            icon: {},
            text: {},
            action: () => {},
          },
        ]
      : [
          {
            type: API.auth.isLoggedIn() ? "" : "button",
            icon: {},
            text: (
              <Button primary thin>
                {t("navbar.login")}
              </Button>
            ),
            action: () => history.push(CONSTANTS.URLS.AUTH.LOGIN),
          },
          {
            type: API.auth.isLoggedIn() ? "" : "button",
            icon: {},
            text: (
              <Button primary ghost thin>
                {t("navbar.signup")}
              </Button>
            ),
            action: () => history.push(CONSTANTS.URLS.AUTH.SIGNUP),
          },
          {
            type: "line",
            icon: {},
            text: {},
            action: () => {},
          },

          {
            type: "title",
            icon: {},
            text: t("navbar.dropdown.other_services"),
            action: () => {},
          },
          {
            type: "option",
            icon: {},
            text: t("navbar.dropdown.companies"),
            action: () => {
              history.push(CONSTANTS.URLS.LANDING.COMPANIES);
            },
          },
          {
            type: "line",
            icon: {},
            text: t("navbar.dropdown.schools"),
            action: () => {},
          },
          {
            type: "lang",
            icon: {},
            text: {},
            action: () => {},
          },
        ];

  const languageOptions = [
    {
      type: "option",
      icon: <ES title="Español" />,
      text: "Es",
      action: () => i18n.changeLanguage("es"),
    },
    {
      type: "option",
      icon: <US title="English" />,
      text: "En",
      action: () => i18n.changeLanguage("en"),
    },
  ];

  const renderMenuProfileOptions = () =>
    menuOptionsProfile.map(({ icon, text, action, type }, key) => {
      switch (type) {
        case "line":
          return <span key={key + 1} className="pz-navbar__menu-line" />;
        case "option":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-item"
            >
              {icon} {text}
            </span>
          );
        default:
          return null;
      }
    });

  const renderLoginProfileMenu = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        <UsernameBadge user={API.auth.getUser()} invert />
      </section>
      <section
        className="pz-navbar__menu-container"
        ref={setCollapsibleElement}
      >
        {renderMenuProfileOptions()}
      </section>
    </section>
  );

  const renderActions = () => {
    if (children) {
      return children;
    }

    if (API.auth.isLoggedIn()) {
      return (
        <>
          <SlideToggle
            duration={500}
            easeCollapse={eases["quintOut"]}
            easeExpand={eases["quintOut"]}
            render={renderLoginProfileMenu}
            bestPerformance
            collapsed
          />
        </>
      );
    } else {
      return (
        <>
          <NavbarLoginForm title={t("navbar.login")} />
          <Button primary ghost>
            <Link to={CONSTANTS.URLS.AUTH.SIGNUP}>{t("navbar.signup")}</Link>
          </Button>
        </>
      );
    }
  };

  const renderMenuProfileOptionsMobile = () =>
    menuOptionsProfileMobile.map(({ icon, text, action, type }, key) => {
      switch (type) {
        case "line":
          return <span key={key + 1} className="pz-navbar__menu-line" />;
        case "option":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-item"
            >
              {icon} {text}
            </span>
          );
        default:
          return null;
      }
    });

  const renderLoginMenuMobile = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        <UsernameBadge user={API.auth.getUser()} invert />
      </section>
      <section
        className="pz-navbar__menu-container"
        ref={setCollapsibleElement}
      >
        {renderMenuProfileOptionsMobile()}
      </section>
    </section>
  );

  const renderActionsMobile = () => {
    if (children) {
      return children;
    }

    if (API.auth.isLoggedIn()) {
      return (
        <>
          <SlideToggle
            duration={500}
            easeCollapse={eases["quintOut"]}
            easeExpand={eases["quintOut"]}
            render={renderLoginMenuMobile}
            bestPerformance
            collapsed
          />
        </>
      );
    } else {
      return (
        <>
          <NavbarLoginForm title={t("navbar.enter")} />
        </>
      );
    }
  };

  const renderMobileMenuOptions = () =>
    menuOptionsMobile.map(({ text, action, type }, key) => {
      switch (type) {
        case "line":
          return <span key={key + 1} className="pz-navbar__menu-line" />;
        case "option":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-item"
            >
              {text}
            </span>
          );
        case "title":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-title"
            >
              {text}
            </span>
          );
        case "button":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-btn"
            >
              {text}
            </span>
          );
        case "lang":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-lang"
            >
              {renderLanguagesMobile()}
            </span>
          );
        default:
          return null;
      }
    });

  const renderMobileMenu = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        <i />
        <i />
        <i />
      </section>
      <section
        className="pz-navbar__menu-container2"
        ref={setCollapsibleElement}
      >
        {renderMobileMenuOptions()}
      </section>
    </section>
  );

  const renderMobile = () => {
    return (
      <>
        <SlideToggle
          duration={500}
          easeCollapse={eases["quintOut"]}
          easeExpand={eases["quintOut"]}
          render={renderMobileMenu}
          bestPerformance
          collapsed
        />
      </>
    );
  };

  const renderLanguageOptions = () =>
    languageOptions.map(({ icon, text, action, type }, key) => {
      switch (type) {
        case "line":
          return <span key={key + 1} className="pz-navbar__menu-line" />;
        case "option":
          return (
            <span
              key={key + 1}
              onClick={action}
              className="pz-navbar__menu-item"
            >
              {icon} {text}
            </span>
          );
        default:
          return null;
      }
    });

  const renderLanguageMenu = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        {currentLang()}
      </section>
      <section
        className="pz-navbar__menu-container-lang"
        ref={setCollapsibleElement}
      >
        {renderLanguageOptions()}
      </section>
    </section>
  );

  const renderLanguages = () => {
    return (
      <>
        <SlideToggle
          duration={500}
          easeCollapse={eases["quintOut"]}
          easeExpand={eases["quintOut"]}
          render={renderLanguageMenu}
          bestPerformance
          collapsed
        />
      </>
    );
  };

  const renderLanguageMenuMobile = ({
    onToggle,
    setCollapsibleElement,
    toggleState,
  }) => (
    <section className="pz-navbar__menu">
      <section
        className={renderClasses({
          "pz-navbar__menu-trigger": true,
          visible: toggleState === "EXPANDED",
        })}
        onClick={onToggle}
      >
        {currentLang()}
      </section>
      <section
        className="pz-navbar__menu-container-lang2"
        ref={setCollapsibleElement}
      >
        {renderLanguageOptions()}
      </section>
    </section>
  );

  const renderLanguagesMobile = () => {
    return (
      <>
        <SlideToggle
          duration={500}
          easeCollapse={eases["quintOut"]}
          easeExpand={eases["quintOut"]}
          render={renderLanguageMenuMobile}
          bestPerformance
          collapsed
        />
      </>
    );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = {
      ...queryString.parse(location.search),
      search: searchValue !== "" ? searchValue : undefined,
    };
    history.push(
      `${CONSTANTS.URLS.COURSES.ALLPage}?${queryString.stringify(params)}`
    );
  };

  return (
    <>
      {!!!auth.user.employee && !!!auth.user.organization ? (
        <NewsBanner />
      ) : null}
      <nav className="pz-navbar">
        <Link
          target="_top"
          to={CONSTANTS.URLS.LANDING.HOME}
          className="pz-navbar__logo"
        >
          <Image name="logos/panzofi.png" />
        </Link>
        <section className="pz-navbar__categories">
          {!!!auth.user.employee && !!!auth.user.organization ? (
            <Dropdown
              className="button_button__custom"
              position="right"
              buttonVariant="tertiary"
              title={
                <section className="Title_title__custom">
                  <h4 className="">{t("navbar.categories")}</h4>
                </section>
              }
            >
              <Dropdown.Item
                className="Item_item__custom bold"
                onClick={() => {
                  history.push(`${CONSTANTS.URLS.COURSES.ALL}`);
                }}
              >
                {t(`navbar.categories_dropdown.all`)}
              </Dropdown.Item>
              <Dropdown.Item
                className="Item_item__custom bold"
                onClick={() => {
                  history.push(`${CONSTANTS.URLS.COURSES.POSTS}`);
                }}
              >
                {t("courses.bar.shorts")}
              </Dropdown.Item>
              {categories.map((cat) => (
                <Dropdown.Item className="Item_item__custom" key={cat.id}>
                  {t(`categories.${cat.slug}.name`)}
                  <Dropdown.Submenu className="Submenu_submenu__custom">
                    {cat.sub_categories.map((subcat) => (
                      <Dropdown.Item
                        key={subcat.id}
                        className="Item_item__custom"
                        onClick={() => {
                          history.push(
                            `${CONSTANTS.URLS.COURSES.ALLPage}?category=${subcat.slug}`
                          );
                        }}
                      >
                        {t(
                          `categories.${cat.slug}.sub_categories.${subcat.slug}.name`
                        )}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Submenu>
                </Dropdown.Item>
              ))}
            </Dropdown>
          ) : null}
          <h4
            className="pz-ai__navbar"
            onClick={() => {
              history.push(`${CONSTANTS.URLS.AI.MAIN}`);
            }}
          >
            {auth.user.employee?.organization?.tier_plan?.name ===
              "ENTERPRISE" ||
            auth.user.organization?.tier_plan?.name === "ENTERPRISE" ||
            (!!!auth.user.employee && !!!auth.user.organization) ? (
              <TypingEffectField
                text={"PanzofIA"}
                speed={200}
              ></TypingEffectField>
            ) : null}
          </h4>
        </section>
        <form onSubmit={handleSubmit} className="pz-navbar__search">
          <input
            type="text"
            value={searchValue}
            onChange={handleChange}
            className="pz-navbar__search--input"
            placeholder={t("navbar.search")}
          />
        </form>

        <section className="pz-navbar__log-btn">
          {renderActionsMobile()}
        </section>
        <section className="pz-navbar__dropdown">{renderMobile()}</section>
        <section className="pz-navbar__actions">{renderActions()}</section>
        <section className="pz-navbar__lang">{renderLanguages()}</section>
      </nav>
    </>
  );
};

Navbar.defaultProps = {
  defaultSearchValue: "",
};

export default connectReduxReducers(withRouter(Navbar), "auth");
