import React from "react";
import { Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { NavbarAdmin } from "apps/admin/components";
import { AdminNewsPage, AdminTriviaPage } from "..";

const AdminToolPage = ({ auth, news }) => {
  const [toggleState, setToggleState] = React.useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const renderTabContent = () => {
    switch (toggleState) {
      case 1:
        return <AdminNewsPage />;

      case 2:
        return <AdminTriviaPage />;

      default:
        return <></>;
    }
  };

  return (
    <main className="pz-admin">
      <Navbar />

      <section className="pz-admin__page">
        {auth.user.is_staff ? (
          <>
            <NavbarAdmin index={3} />

            <section className="pz-admin__content">
              <section className="pz-admin__tabcontainer">
                <section className="pz-admin__tabs">
                  <h4
                    className={
                      toggleState === 1
                        ? "pz-admin__active-tab"
                        : "pz-admin__tab"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    Banner
                  </h4>
                  <h4
                    className={
                      toggleState === 2
                        ? "pz-admin__active-tab"
                        : "pz-admin__tab"
                    }
                    onClick={() => toggleTab(2)}
                  >
                    Trivias
                  </h4>
                </section>
              </section>

              {renderTabContent()}
            </section>
          </>
        ) : (
          <>No tienes permiso para entrar aca</>
        )}
      </section>
    </main>
  );
};

export default connectReduxReducers(AdminToolPage, "auth", "news");
