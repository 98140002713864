import React from "react";
import { Navbar, NavbarConfig } from "components/panzofi";
import {
  Button,
  FileField,
  Form,
  InputField,
  TextareaField,
} from "components/ui";
import { connectReduxReducers } from "hocs";
import { useTranslation } from "react-i18next";

const SettingsProfilePage = ({ auth, profile }) => {
  const { t } = useTranslation();
  const editProfile = (data) => {
    profile.updateProfile(auth.user?.slug, data, ({ status }) => {
      if (status === 200) {
      }
    });
  };

  if (auth.user?.organization)
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          No tienes permiso para realizar esta acción.
        </section>
      </main>
    );
  else if (auth.user?.employee)
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          No tienes permiso para realizar esta acción.
        </section>
      </main>
    );
  else
    return (
      <main className="pz-settings">
        <Navbar />
        <section className="pz-settings__page">
          <NavbarConfig index={1} />
          <section className="pz-settings__content">
            <h1>{t("others.settings.profile.title")}</h1>
            <p>
              {t(`profile.modal.subtitle.edit_subtitle`, {
                name: auth.user?.first_name,
              })}
            </p>
            <Form onSubmit={editProfile}>
              {({ onChange, data: { introduction } }) => (
                <React.Fragment>
                  <FileField
                    errMsg={t(`profile.modal.file_input.error`)}
                    nameDefault={t(`profile.modal.file_input.state`)}
                    label={t(`profile.modal.file_input.title`)}
                    name="picture"
                    onChange={onChange}
                    notRequired
                  />
                  <InputField
                    label={t(`profile.modal.website_input.title`)}
                    name="website"
                    onChange={onChange}
                    type="url"
                    defaultValue={auth.user?.profile?.website}
                    notRequired
                  />
                  <InputField
                    label={t(`profile.modal.location_input.title`)}
                    name="location"
                    onChange={onChange}
                    defaultValue={auth.user?.profile?.location}
                    notRequired
                  />
                  <InputField
                    label={t(`profile.modal.phone_input.title`)}
                    name="phone"
                    onChange={onChange}
                    type="number"
                    defaultValue={auth.user?.profile?.phone}
                    notRequired
                  />
                  <TextareaField
                    label={`${t(`profile.modal.description_input.title`)} (${
                      typeof introduction === "string" ? introduction.length : 0
                    }/300)`}
                    maxLength={300}
                    name="introduction"
                    onChange={onChange}
                    type="number"
                    defaultValue={auth.user?.profile?.introduction}
                    notRequired
                  />
                  <dl>
                    <dd>
                      {" "}
                      <h6>{t(`profile.modal.info_input.title`)}</h6>
                    </dd>
                  </dl>
                  <section className="pz-auth-form__buttons">
                    <Button type="submit" primary thin>
                      {t(`profile.modal.submit_button.edit_title`)}
                    </Button>
                  </section>
                </React.Fragment>
              )}
            </Form>
          </section>
        </section>
      </main>
    );
};

export default connectReduxReducers(SettingsProfilePage, "auth", "profile");
