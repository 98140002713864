import React from "react";
import { Footer, Navbar } from "components/panzofi";
import { connectReduxReducers } from "hocs";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { CourseCard, ProfileCard } from "apps/courses/components";
import { useTranslation } from "react-i18next";
import { PostCard, PostDetailModal } from "apps/posts/components";
import { ForumCard } from "apps/forums/components";

const CoursesAll = ({
  courses: reduxCourses,
  posts: ReduxPosts,
  profile,
  threads,
  location,
  history,
  auth,
}) => {
  const [courses, setCourses] = React.useState([]);
  const [recommendedCourses, setRecommendedCourses] = React.useState([]);
  const [forums, setForums] = React.useState([]);
  const [profiles, setProfiles] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [recommendedPosts, setRecommendedPosts] = React.useState([]);
  const [toggleState, setToggleState] = React.useState(1);
  const [limit] = React.useState(30);
  const [offset] = React.useState(0);
  const [postDetailModalVisible, setPostDetailModalVisible] =
    React.useState(false);
  const [postToEdit, setPostToEdit] = React.useState(null);

  const { t } = useTranslation();

  React.useEffect(() => {
    getMoreCourses();
    // eslint-disable-next-line
  }, []);

  const getCoursesRecommended = () => {
    reduxCourses.getCoursesRecommended(({ data, status }) => {
      setRecommendedCourses(status === 200 ? data : null);
    });
  };

  const getPostsRecommended = () => {
    ReduxPosts.getPostsRecommended(({ data, status }) => {
      setRecommendedPosts(status === 200 ? data : null);
    });
  };

  const getMoreCourses = () => {
    const params = {
      search: getSearchParams()?.search,
      category__slug: getSearchParams()?.category,
      limit: limit,
      offset: offset,
      ordering: "-num_reaction",
    };
    reduxCourses.getCourses(params, ({ data, status }) => {
      setCourses(status === 200 ? data.results : null);
      if (data.results === null || data.results.length === 0) {
        getCoursesRecommended();
      }
    });
  };

  const getMorePosts = () => {
    const params = {
      search: getSearchParams()?.search,
      category__slug: getSearchParams()?.category,
      limit: limit,
      offset: offset,
      ordering: "-num_reaction",
    };
    ReduxPosts.getPosts(params, ({ data, status }) => {
      setPosts(status === 200 ? data.results : null);

      if (data.results === null || data.results.length === 0) {
        getPostsRecommended();
      }
    });
  };

  const getMoreProfiles = () => {
    const params = {
      search: getSearchParams()?.search,
      // category__slug: getSearchParams()?.category,
      limit: limit,
      offset: offset,
      ordering: "-professor_exp",
    };
    profile.getProfiles2(params, ({ data, status }) => {
      setProfiles(status === 200 ? data.results : null);
    });
  };

  const getThreads = () => {
    const params = {
      search: getSearchParams()?.search,
      limit: limit,
      offset: offset,
      ordering: "created",
    };
    threads.getForums(params, ({ data, status }) => {
      setForums(status === 200 ? data.results : null);
    });
  };

  const renderForums = () =>
    forums.map((forum, key) => {
      return <ForumCard forum={forum} key={key} />;
    });

  const renderCourses = (courses) => {
    return (
      <>
        {courses.map((course, key) => (
          <CourseCard course={course} key={key} />
        ))}
      </>
    );
  };

  const renderPosts = (posts) => {
    return (
      <>
        {posts.map((post, key) => (
          <PostCard post={post} key={key} onDetailView={openModalDetailPost} />
        ))}
      </>
    );
  };

  const renderProfiles = (courses) => {
    return (
      <>
        {courses.map((prof, key) => (
          <ProfileCard prof={prof} key={key} />
        ))}
      </>
    );
  };

  const toggleTab = (index) => {
    switch (index) {
      case 1:
        getMoreCourses();
        break;
      case 2:
        getMorePosts();
        break;
      case 3:
        getMoreProfiles();
        break;
      case 4:
        getThreads();
        break;

      default:
        break;
    }
    setToggleState(index);
  };

  const renderTabContent = () => {
    switch (toggleState) {
      case 1:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {t("others.search.lessons.subtitle")} ({courses?.length})
                </span>
              </h4>
              {courses?.length ? (
                <section className="pz-profile__courses--list">
                  {renderCourses(courses)}
                </section>
              ) : (
                <>
                  <section className="pz-profile__courses--list recommend">
                    <h3>
                      {t("others.search.not_found")} "
                      {getSearchParams()?.search}"
                    </h3>
                    <h4>{t("others.search.recommendation")}</h4>
                  </section>
                  <section className="pz-profile__courses--list">
                    {renderCourses(recommendedCourses)}
                  </section>
                </>
              )}
            </section>
          </>
        );

      case 2:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {t("others.search.posts.subtitle")} ({posts?.length})
                </span>
              </h4>
              {posts?.length ? (
                <section className="pz-profile__courses--list">
                  {renderPosts(posts)}
                </section>
              ) : (
                <>
                  <section className="pz-profile__courses--list recommend">
                    <h3>
                      {t("others.search.not_found")} "
                      {getSearchParams()?.search}"
                    </h3>
                    <h4>{t("others.search.recommendation")}</h4>
                  </section>
                  <section className="pz-profile__courses--list">
                    {renderPosts(recommendedPosts)}
                  </section>
                </>
              )}
            </section>
          </>
        );
      case 3:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {t("others.search.profiles.subtitle")} ({profiles?.length})
                </span>
              </h4>
              {profiles?.length ? (
                <section className="pz-forum__forums--list">
                  {renderProfiles(profiles)}
                </section>
              ) : (
                <section className="pz-profile__courses--list recommend">
                  <h3>
                    {t("others.search.not_found")} "{getSearchParams()?.search}"
                  </h3>
                </section>
              )}
            </section>
          </>
        );
      case 4:
        return (
          <>
            <section className="pz-profile__courses">
              <h4 className="pz-profile__courses--title">
                <span>
                  {t("others.search.forums.subtitle")} ({forums?.length})
                </span>
              </h4>
              {forums?.length ? (
                <section className="pz-forum__forums--list">
                  {renderForums(forums)}
                </section>
              ) : (
                <section className="pz-profile__courses--list recommend">
                  <h3>
                    {t("others.search.not_found")} "{getSearchParams()?.search}"
                  </h3>
                </section>
              )}
            </section>
          </>
        );
      default:
        return <></>;
    }
  };

  const closeModalDetailPost = () => {
    setPostToEdit(null);
    setPostDetailModalVisible(false);
  };

  const openModalDetailPost = (post) => {
    setPostToEdit(post);
    setPostDetailModalVisible(true);
  };

  const getSearchParams = () => queryString.parse(location.search);

  return (
    <main className="pz-profile">
      <Navbar defaultSearchValue={getSearchParams()?.search} />
      <section className="pz-profile__tabcontainer">
        {!!!auth.user?.employee && !!!auth.user?.organization ? (
          <section className="pz-profile__tabs">
            <h4
              className={
                toggleState === 1 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(1)}
            >
              {t("others.search.lessons.title")}
            </h4>
            <h4
              className={
                toggleState === 2 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(2)}
            >
              {t("others.search.posts.title")}
            </h4>
            <h4
              className={
                toggleState === 3 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(3)}
            >
              {t("others.search.profiles.title")}
            </h4>
            <h4
              className={
                toggleState === 4 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(4)}
            >
              {t("others.search.forums.title")}
            </h4>
          </section>
        ) : (
          <section className="pz-profile__tabs">
            <h4
              className={
                toggleState === 1 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(1)}
            >
              {t("others.search.lessons.title")}
            </h4>
            <h4
              className={
                toggleState === 4 ? "pz-profile__active-tab" : "pz-profile__tab"
              }
              onClick={() => toggleTab(4)}
            >
              {t("others.search.forums.title")}
            </h4>
          </section>
        )}
        {renderTabContent()}
        <Footer />
      </section>
      <PostDetailModal
        postId={postToEdit?.id}
        is_visible={postDetailModalVisible}
        closeModal={closeModalDetailPost}
      />
    </main>
  );
};

export default connectReduxReducers(
  withRouter(CoursesAll),
  "courses",
  "posts",
  "profile",
  "threads",
  "auth"
);
