import React from "react";
import { Modal } from "components/common";
import { Form, SelectField } from "components/ui";
import { connectReduxReducers } from "hocs";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
const BuyPackageModal = ({ auth, organizations, is_visible, closeModal }) => {
  // const planIdBasic = "P-9BR69560G70637125M4IH4BA";
  // const planIdBusiness = "P-60P75720TB918880WM4IH6TI";
  // const planIdEnterprice = "P-35B57990GT162293LM4IIAGY";
  const planSandboxIds = {
    BASIC: "P-1YH5167763220891FM4ICHOQ",
    BUSINESS: "P-55V48116FH260372VM42LSKA",
    ENTERPRISE: "P-8LW97434220600941M4ICOUY",
  };

  let number = 5;
  let tier = "BASIC";
  let tierId = planSandboxIds.BASIC;

  // const initialOptionsSandbox = {
  //   clientId:
  //     "AaQaJEJuFY84KiASHs2d6VJ5SZVG8YXhFuyHAFh7eCf8fLUU-hNNHevlm3HowEfsA1C0uZpHZMNz5Oje",
  //   currency: "USD",
  //   intent: "subscription",
  //   vault: true,
  // };
  const initialOptionsSandbox = {
    clientId:
      "ATicma2J6xZLK7ZVKTMGCIfFqVRus9JxXYma-ZrUTJ0XUKJmB07Wx4Mr7zbe7KgdAbDV_4H48vlQ-d3k",
    currency: "USD",
    intent: "subscription",
    vault: true,
  };

  const options = Array.from({ length: 100 }, (_, i) => {
    const value = (i + 1) * 5;
    return { value, label: value.toString() };
  });

  const handleApprove = (data) => {
    const dataForm = {
      subscription_id: data.subscriptionID,
      employee_count: number,
      tier: tier,
    };
    console.log("Subscription approved successfully:", data.subscriptionID);
    organizations.createPaymentOrganization(
      auth.user?.id,
      dataForm,
      (response) => {
        if (response.status === 201) closeModal(true);
      }
    );
  };

  const handleChange = (data) => {
    if (data.number_2) {
      number = data.number_2;
    }
    if (data.tier) {
      tier = data.tier;
      tierId = planSandboxIds[data.tier];
    }
  };

  const handleSubmit = (data) => {
    console.log(data);
  };
  return (
    <Modal
      is_visible={is_visible}
      closeModal={closeModal}
      exitOnOverlayClick
      contentClassName="grid grid-gap-20"
    >
      <h1>Completa tu compra</h1>
      <p>
        A continuación, selecciona el número de empleados y el paquete que
        deseas adquirir, Para más información, contáctanos al correo{" "}
        <a href="mailto:info@panzofi.com">info@panzofi.com</a>
      </p>
      <Form onSubmit={handleSubmit} onChange={handleChange} className="pz-form">
        {({ onChange }) => (
          <React.Fragment>
            <PayPalScriptProvider options={initialOptionsSandbox}>
              <SelectField
                label={"Numero de empleados"}
                name="number_2"
                onChange={onChange}
                options={options}
                defaultValue={{ value: 5, label: "5" }}
                notRequired={false}
              />
              <SelectField
                label={"Paquete"}
                name="tier"
                onChange={onChange}
                options={[
                  { value: "BASIC", label: "Basic" },
                  { value: "BUSINESS", label: "Business" },
                  { value: "ENTERPRISE", label: "Enterprise" },
                ]}
                defaultValue={{ value: "BASIC", label: "Basic" }}
                notRequired={false}
              />
              <br />
              <br />
              <br />
              <br />
              <br />
              <PayPalButtons
                style={{ layout: "vertical" }}
                createSubscription={(data, actions) => {
                  return actions.subscription.create({
                    plan_id: tierId,
                    quantity: number,
                  });
                }}
                onApprove={(data, actions) => handleApprove(data, actions)}
                onError={(error) =>
                  console.error("Error in PayPal Buttons:", error)
                }
              />
            </PayPalScriptProvider>
          </React.Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default connectReduxReducers(BuyPackageModal, "auth", "organizations");
