import { Button } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  DeleteNewsModal,
  NewsCard,
  NewsModal,
  PrivacyNewsModal,
} from "./components";

const AdminNewsPage = ({ auth, news }) => {
  const [newsList, setNewsList] = React.useState([]);
  const [newsModalVisible, setNewsModalVisible] = React.useState(false);
  const [newsDeleteModalVisible, setNewsDeleteModalVisible] =
    React.useState(false);
  const [newsPrivacyModalVisible, setNewsPrivacyModalVisible] =
    React.useState(false);
  const [newsToEdit, setNewsToEdit] = React.useState(null);

  React.useEffect(() => {
    getNews();
    // eslint-disable-next-line
  }, []);

  const getNews = () => {
    news.getAdminNews(({ data, status }) => {
      setNewsList(status === 200 ? data : null);
    });
  };
  const closeModalNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsModalVisible(false);
  };
  const closeModalDeleteNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsDeleteModalVisible(false);
  };
  const closeModalPrivacyNews = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getNews();
    }
    setNewsToEdit(null);
    setNewsPrivacyModalVisible(false);
  };
  const openNewsEdit = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsModalVisible(true);
  };
  const openModalDeleteNews = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsDeleteModalVisible(true);
  };
  const openModalPrivacyNews = (currentNews) => {
    setNewsToEdit(currentNews);
    setNewsPrivacyModalVisible(true);
  };

  const renderPosts = () =>
    newsList.map((post, key) => {
      return (
        <NewsCard
          currentNews={post}
          key={key}
          onEditPost={openNewsEdit}
          onDeletePost={openModalDeleteNews}
          onChangeStatus={openModalPrivacyNews}
        />
      );
    });
  return (
    <>
      <section>
        <h1>Banner de Noticias</h1>
        <section className="pz-profile__courses">
          <h4 className="pz-profile__courses--title">
            <span>
              {"Noticias subidas"} ({newsList?.length})
            </span>

            <section>
              <Button
                thin
                ghost
                primary
                onClick={() => {
                  setNewsModalVisible(true);
                }}
              >
                {"Subir una noticia al banner"}
              </Button>
            </section>
          </h4>
          {newsList?.length ? (
            <section className="pz-profile__courses--list">
              {renderPosts()}
            </section>
          ) : (
            <section className="pz-profile__courses--list empty">
              <span>{"No ha noticias del banner"}</span>
            </section>
          )}
        </section>
      </section>
      <NewsModal
        currentNews={newsToEdit}
        is_visible={newsModalVisible}
        closeModal={closeModalNews}
      />
      <DeleteNewsModal
        newsId={newsToEdit?.id}
        is_visible={newsDeleteModalVisible}
        closeModal={closeModalDeleteNews}
      />
      <PrivacyNewsModal
        currentNews={newsToEdit}
        is_visible={newsPrivacyModalVisible}
        closeModal={closeModalPrivacyNews}
      />
    </>
  );
};

export default connectReduxReducers(withRouter(AdminNewsPage), "news");
