import axios from "axios";
import queryString from "query-string";

class PanzofiAPI {
  constructor(http, interceptor) {
    this.http = http;
    this.cancelToken = axios.CancelToken;

    this.http.interceptors.request.use(interceptor.requestAuthorizationHandler);

    this.http.interceptors.response.use(
      interceptor.successHandler,
      interceptor.errorHandler
    );
  }

  getProfile = (slug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/users/${slug}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getProfiles = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/users/admin/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminQuestionnaires = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/users/admin/questionnaire?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getUsersLogin = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/users/admin/lastlogin?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getUsersNoRetention = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/users/admin/noretention?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getUsersRetention = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/users/admin/retention?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getProfiles2 = (successCallback, errorCallback, filter) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/profiles/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updateUser = (userSlug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/users/${userSlug}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createProfile = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/profiles/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updateProfile = (userSlug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/profiles/${userSlug}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createQuestionneire = (userSlug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/profiles/${userSlug}/questionnaire/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };
  getQuestionneires = (userSlug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/profiles/${userSlug}/questionnaire/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCategories = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get("/courses/categories/", {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createPost = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/posts/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getPosts = (successCallback, errorCallback, filter = {}) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/posts/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getPostsRecommended = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/posts/recommended/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminPosts = (successCallback, errorCallback, filter = {}) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/posts/admin/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyPost = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/posts/${id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getPost = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/posts/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deletePost = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/posts/${id}/delete/`, { cancelToken: source.token })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  createCourse = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/courses/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createCourseSubscription = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/courses/${id}/subscribe`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCourseSubscription = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/${id}/subscribtion/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };
  deleteCourseSubscription = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/courses/${id}/subscribtion/delete/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };
  getCourseSubscriptions = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/profiles/${id}/subscribtions/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyCourse = (slug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/courses/${slug}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCourses = (successCallback, errorCallback, filter = {}) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCoursesRecommended = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/recommended/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminCourses = (successCallback, errorCallback, filter = {}) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/admin/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCourse = (slug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/${slug}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteCourse = (slug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/courses/${slug}/delete/`, { cancelToken: source.token })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  createModule = (course_slug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/courses/${course_slug}/modules/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updateModule = (
    course_slug,
    module_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/courses/${course_slug}/modules/${module_id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteModule = (course_slug, module_id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/courses/${course_slug}/modules/${module_id}/delete/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  updateModuleOrder = (
    course_slug,
    module_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .put(
        `/courses/${course_slug}/modules/${module_id}/update-module-order/`,
        data,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  deleteModuleContent = (
    course_slug,
    module_id,
    lesson_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .delete(
        `/courses/${course_slug}/modules/${module_id}/content/${lesson_id}/delete/`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };
  createModuleContent = (
    course_slug,
    module_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/courses/${course_slug}/modules/${module_id}/content/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updateModuleContent = (
    course_slug,
    module_id,
    lesson_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .patch(
        `/courses/${course_slug}/modules/${module_id}/content/${lesson_id}/`,
        data,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updateContentOrder = (
    course_slug,
    module_id,
    lesson_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .put(
        `/courses/${course_slug}/modules/${module_id}/content/${lesson_id}/update-content-order/`,
        data,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  getPracticalContents = (
    course_slug,
    module_id,
    content_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .get(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}/practical/`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createPracticalContent = (
    course_slug,
    module_id,
    content_id,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .post(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}/practical/`,
        data,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  updatePracticalContent = (
    course_slug,
    module_id,
    content_id,
    practicalId,
    data,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .patch(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}/practical/${practicalId}`,
        data,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getPracticalContent = (
    course_slug,
    module_id,
    content_id,
    practical_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .get(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}/practical/${practical_id}`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deletePracticalContent = (
    course_slug,
    module_id,
    content_id,
    practical_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .delete(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}/practical/${practical_id}/delete/`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  getModule = (
    course_slug,
    module_id,
    content_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/courses/${course_slug}/modules/${module_id}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getModuleContent = (
    course_slug,
    module_id,
    content_id,
    successCallback,
    errorCallback
  ) => {
    const source = this.cancelToken.source();
    this.http
      .get(
        `/courses/${course_slug}/modules/${module_id}/content/${content_id}`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createComment = (data, model, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/comments/${model}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCommentEmployees = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/comments/${id}/employees/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createReaction = (data, model, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/reactions/${model}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getReactionEmployees = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/reactions/${id}/employees/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  signupOrganization = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/organizations/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createPaymentOrganization = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/organizations/${id}/payment/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  activeAccount = (code, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .put(
        `/organizations/activate-token/${code}`,
        {},
        {
          cancelToken: source.token,
        }
      )
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  sendActiveAccount = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/organizations/activation-token`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCourseEmployeesviews = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/organizations/${id}/views-courses`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getCourseQuizzes = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/organizations/${id}/quizzes-courses`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createEmployee = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/organizations/employees/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getEmployees = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get("/organizations/employees/", {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getEmployeesData = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/organizations/employees/${id}/views-courses`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getEmployeesDataReaction = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/organizations/employees/${id}/reactions-courses`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getEmployeesDataComments = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/organizations/employees/${id}/comments-courses`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createContentView = (data, model, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/contentviews/${model}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getContentView = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/contentviews/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createNews = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/news/admin/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getNews = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/news/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminNews = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/news/admin/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyNews = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/news/${id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  // getNews = (id, successCallback, errorCallback) => {
  //   const source = this.cancelToken.source();
  //   this.http
  //     .get(`/news/${id}`, {
  //       cancelToken: source.token,
  //     })
  //     .then((response) => successCallback(response))
  //     .catch((error) => errorCallback(error));
  //   return source;
  // };

  deleteNews = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/news/${id}/delete/`, { cancelToken: source.token })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  createAdminTrivia = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/trivia/admin/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminTrivias = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/trivia/admin/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteAdminTrivia = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/trivia/admin/${id}/`, { cancelToken: source.token })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  modifyAdminTrivia = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/trivia/admin/${id}/`, data, { cancelToken: source.token })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
  };

  getTrivias = (successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/trivia/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createTriviaAttempt = (id_trivia, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/trivia/admin/${id_trivia}/attempts/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getTriviaAttempt = (id_attempt, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/trivia/attempts/${id_attempt}/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createQuiz = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/quizzes/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteQuiz = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/quizzes/${id}/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getQuiz = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/quizzes/${id}/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyQuiz = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`/quizzes/${id}/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createQuizAttempt = (id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`/quizzes/${id}/attempts/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getQuizAttempt = (id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/quizzes/${id}/attempts/retrieve/`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createThread = (slug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`forums/${slug}/threads/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getThreads = (slug, filter = {}, successCallback, errorCallback) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`forums/${slug}/threads/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getThread = (slug, id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`forums/${slug}/threads/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyThread = (slug, id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`forums/${slug}/threads/${id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteThread = (slug, id, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`forums/${slug}/threads/${id}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createForum = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/forums/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getForum = (slug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .get(`/forums/${slug}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getForums = (filter = {}, successCallback, errorCallback) => {
    const params = queryString.stringify(filter);
    const source = this.cancelToken.source();
    this.http
      .get(`/forums/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  modifyForum = (slug, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .patch(`forums/${slug}`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteForum = (slug, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`forums/${slug}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  createThreadResponse = (slug, id, data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post(`forums/${slug}/threads/${id}/responses/`, data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  change_password2 = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/auth/change-password2", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  deleteAccount = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .delete(`/auth/delete/${data.id}`, data, {
        cancelToken: source.token,
      })
      .then((response) => {
        successCallback(response);
      })
      .catch((error) => {
        errorCallback(error);
      });
    return source;
  };

  createAIRequest = (data, successCallback, errorCallback) => {
    const source = this.cancelToken.source();
    this.http
      .post("/mlms/", data, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  listAIRequest = (successCallback, errorCallback, filter) => {
    const source = this.cancelToken.source();
    const params = queryString.stringify(filter);
    this.http
      .get(`/mlms/admin/?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminGeneral = (successCallback, errorCallback, filter) => {
    const source = this.cancelToken.source();
    const params = queryString.stringify(filter);
    this.http
      .get(`/admin/general?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminInteraction = (successCallback, errorCallback, filter) => {
    const source = this.cancelToken.source();
    const params = queryString.stringify(filter);
    this.http
      .get(`/admin/interaction?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };

  getAdminViews = (successCallback, errorCallback, filter) => {
    const source = this.cancelToken.source();
    const params = queryString.stringify(filter);
    this.http
      .get(`/admin/views?${params}`, {
        cancelToken: source.token,
      })
      .then((response) => successCallback(response))
      .catch((error) => errorCallback(error));
    return source;
  };
}

export default PanzofiAPI;
