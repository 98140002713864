import React from "react";
import { CONSTANTS } from "shared/constants";
import {
  faCaretDown,
  faCaretUp,
  faEdit,
  faFileAlt,
  faQuestionCircle,
  faTrash,
  faVideo,
  faVolumeUp,
  faAngleUp,
  faAngleDown,
  faPencilAlt,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SlideToggle from "react-slide-toggle";
import eases from "eases";
import { renderClasses } from "shared/common/functions";
import ViewContent from "../view-content";
import API from "core/api";
import QuizPassed from "../quiz-passed";

const CourseModules = ({
  course,
  employee_tier,
  match,
  onEditModule,
  onDeleteModule,
  onEditLesson,
  onDeleteLesson,
  onUpdateModuleOrder,
  onUpdateContentOrder,
  onDeleteQuiz,
  onEditQuiz,
  onQuizAttempt,
}) => {
  const getIcon = (type) => {
    switch (type) {
      case CONSTANTS.FILE_TYPES.AUDIO:
        return faVolumeUp;
      case CONSTANTS.FILE_TYPES.DOCUMENT:
        return faFileAlt;
      case CONSTANTS.FILE_TYPES.VIDEO:
        return faVideo;
      default:
        return faQuestionCircle;
    }
  };
  const renderModuleContent = (moduleContent, moduleId) =>
    moduleContent.map(({ id, title, description, type }, key) => (
      <section
        key={id}
        className={renderClasses({
          "pz-course-modules__module--lesson": true,
          selected: id === match.params?.lessonId,
        })}
      >
        <Link
          className="pz-course-modules__module--lesson--content"
          to={CONSTANTS.URLS.COURSES.LESSON(course?.slug, moduleId, id)}
        >
          {API.auth.isLoggedIn() ? <ViewContent id_content={id} /> : null}

          <FontAwesomeIcon
            icon={getIcon(type)}
            className="pz-course-modules__module--lesson--icon"
          />
          <span className="pz-course-modules__module--lesson--title">
            {title}
          </span>
          <h5 className="pz-course-modules__module--lesson--description">
            {description}
          </h5>
        </Link>
        <section className="pz-course-modules__module--lesson--actions">
          {typeof onEditLesson === "function" ? (
            <FontAwesomeIcon
              className="pz-course-modules__module--lesson--actions--edit"
              icon={faEdit}
              onClick={() =>
                onEditLesson({ ...moduleContent[key], moduleId: moduleId })
              }
            />
          ) : null}
          {typeof onDeleteLesson === "function" ? (
            <FontAwesomeIcon
              className="pz-course-modules__module--lesson--actions--edit"
              icon={faTrash}
              onClick={() => {
                onDeleteLesson({ ...moduleContent[key], moduleId: moduleId });
              }}
            />
          ) : null}
          <section className="pz-course-modules__module--arrows">
            {typeof onUpdateContentOrder === "function" ? (
              key === 0 ? (
                <FontAwesomeIcon
                  icon={faCaretUp}
                  style={{ color: "#E5E5E5" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretUp}
                  onClick={() =>
                    onUpdateContentOrder(
                      moduleId,
                      moduleContent[key].id,
                      key - 1
                    )
                  }
                />
              )
            ) : null}
            {typeof onUpdateContentOrder === "function" ? (
              key === moduleContent.length - 1 ? (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ color: "#E5E5E5" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCaretDown}
                  onClick={() =>
                    onUpdateContentOrder(
                      moduleId,
                      moduleContent[key].id,
                      key + 1
                    )
                  }
                />
              )
            ) : null}
          </section>
        </section>
      </section>
    ));

  const renderModules = (modules) =>
    modules.map(({ id, title, description, content, module_quiz }, key) => (
      <SlideToggle
        key={id}
        duration={500}
        easeCollapse={eases["quintOut"]}
        easeExpand={eases["quintOut"]}
        render={({ onToggle, setCollapsibleElement, toggleState }) => (
          <section className="pz-course-modules__module">
            <section
              className="pz-course-modules__module--header"
              onClick={onToggle}
            >
              <section className="grid-columns grid-gap-20 grid-items-middle">
                <div className="pz-course-modules__module--info">
                  <h5>{content.length}</h5>
                  <FontAwesomeIcon
                    icon={
                      toggleState === "EXPANDED" || toggleState === "EXPANDING"
                        ? faAngleUp
                        : faAngleDown
                    }
                  />
                </div>
                <section>
                  <h4 className="pz-course-modules__module--title bold">
                    Módulo {key + 1}: {title}
                  </h4>
                  <h5 className="pz-course-modules__module--description">
                    {description}
                  </h5>
                </section>
              </section>
              <section className="grid-columns grid-gap-20 grid-items-middle">
                {typeof onEditModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => onEditModule(modules[key])}
                  />
                ) : null}
                {typeof onDeleteModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => onDeleteModule(modules[key])}
                  />
                ) : null}
                <section className="pz-course-modules__module--arrows">
                  {typeof onUpdateModuleOrder === "function" ? (
                    key === 0 ? (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        style={{ color: "#E5E5E5" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretUp}
                        onClick={() =>
                          onUpdateModuleOrder(modules[key], key - 1)
                        }
                      />
                    )
                  ) : null}
                  {typeof onUpdateModuleOrder === "function" ? (
                    key === modules.length - 1 ? (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#E5E5E5" }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        onClick={() =>
                          onUpdateModuleOrder(modules[key], key + 1)
                        }
                      />
                    )
                  ) : null}
                </section>
              </section>
            </section>
            <section
              className="pz-course-modules__module--body"
              ref={setCollapsibleElement}
            >
              {renderModuleContent(content, id)}
              {(module_quiz && !course?.for_company) ||
              (module_quiz && course?.for_company && employee_tier === true) ? (
                <section
                  className={renderClasses({
                    "pz-course-modules__module--lesson": true,
                  })}
                >
                  <section
                    className="pz-course-modules__module--lesson--content"
                    onClick={
                      typeof onQuizAttempt === "function"
                        ? () => onQuizAttempt(module_quiz)
                        : null
                    }
                  >
                    {API.auth.isLoggedIn() ? (
                      <QuizPassed id_quiz={module_quiz.id} />
                    ) : null}
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="pz-course-modules__module--lesson--icon"
                    />
                    <span className="pz-course-modules__module--lesson--title">
                      {module_quiz.title}
                    </span>
                    <h5 className="pz-course-modules__module--lesson--description">
                      {module_quiz.description}
                    </h5>
                  </section>
                  <section className="pz-course-modules__module--lesson--actions">
                    {typeof onEditLesson === "function" ? (
                      <FontAwesomeIcon
                        className="pz-course-modules__module--lesson--actions--edit"
                        icon={faEdit}
                        onClick={() => onEditQuiz(module_quiz)}
                      />
                    ) : null}
                    {typeof onDeleteLesson === "function" ? (
                      <FontAwesomeIcon
                        className="pz-course-modules__module--lesson--actions--edit"
                        icon={faTrash}
                        onClick={() => onDeleteQuiz(module_quiz)}
                      />
                    ) : null}
                  </section>
                </section>
              ) : null}
            </section>
          </section>
        )}
        bestPerformance
        collapsed={
          match.params?.moduleId ? id !== match.params?.moduleId : key !== 0
        }
      />
    ));

  if (!!!course?.for_company)
    return (
      <>
        <section className="pz-course-modules">
          {renderModules(course?.modules || [])}
        </section>
        {course?.course_quiz ? (
          <section className="pz-course-modules__quiz">
            <section className="pz-course-modules__quiz--header">
              <section
                className="pz-course-modules__quiz--body"
                onClick={
                  typeof onQuizAttempt === "function"
                    ? () => onQuizAttempt(course?.course_quiz)
                    : null
                }
              >
                <div className="pz-course-modules__quiz--info">
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="pz-course-modules__quiz--secondary"
                  ></FontAwesomeIcon>
                </div>
                <section>
                  <h4 className="pz-course-modules__quiz--title bold">
                    {course.course_quiz.title}
                  </h4>
                  <h5 className="pz-course-modules__quiz--description">
                    {course.course_quiz.description}
                  </h5>
                </section>
              </section>
              <section className="pz-course-modules__quiz--body">
                {typeof onEditModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => onEditQuiz(course.course_quiz)}
                  />
                ) : null}
                {typeof onDeleteModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => onDeleteQuiz(course.course_quiz)}
                  />
                ) : null}
                <section>
                  {API.auth.isLoggedIn() ? (
                    <QuizPassed id_quiz={course.course_quiz.id} />
                  ) : null}
                </section>
              </section>
            </section>
          </section>
        ) : null}
        {course?.forum ? (
          <section className="pz-course-modules__forum2">
            <Link
              className="pz-course-modules__forum2--header"
              to={CONSTANTS.URLS.FORUM.FORUM_DETAIL(course.forum.slug)}
            >
              <section className="pz-course-modules__forum2--body">
                <div className="pz-course-modules__forum2--info">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    className="pz-course-modules__forum2--secondary"
                  ></FontAwesomeIcon>
                </div>
                <section>
                  <h4 className="pz-course-modules__forum2--title bold">
                    Foro
                  </h4>
                  <h5 className="pz-course-modules__forum2--description">
                    ¡Este curso tiene un foro donde puedes dejar preguntas!
                  </h5>
                </section>
              </section>
            </Link>
          </section>
        ) : null}
      </>
    );
  else
    return (
      <>
        <section className="pz-course-modules">
          {renderModules(course?.modules || [])}
        </section>
        {course?.course_quiz && employee_tier === true ? (
          <section className="pz-course-modules__quiz">
            <section className="pz-course-modules__quiz--header">
              <section
                className="pz-course-modules__quiz--body"
                onClick={
                  typeof onQuizAttempt === "function"
                    ? () => onQuizAttempt(course?.course_quiz)
                    : null
                }
              >
                <div className="pz-course-modules__quiz--info">
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="pz-course-modules__quiz--secondary"
                  ></FontAwesomeIcon>
                </div>
                <section>
                  <h4 className="pz-course-modules__quiz--title bold">
                    {course.course_quiz.title}
                  </h4>
                  <h5 className="pz-course-modules__quiz--description">
                    {course.course_quiz.description}
                  </h5>
                </section>
              </section>
              <section className="pz-course-modules__quiz--body">
                {typeof onEditModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => onEditQuiz(course.course_quiz)}
                  />
                ) : null}
                {typeof onDeleteModule === "function" ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => onDeleteQuiz(course.course_quiz)}
                  />
                ) : null}
                <section>
                  {API.auth.isLoggedIn() ? (
                    <QuizPassed id_quiz={course.course_quiz.id} />
                  ) : null}
                </section>
              </section>
            </section>
          </section>
        ) : null}
        {course?.forum && employee_tier === true ? (
          <section className="pz-course-modules__forum2">
            <Link
              className="pz-course-modules__forum2--header"
              to={CONSTANTS.URLS.FORUM.FORUM_DETAIL(course.forum.slug)}
            >
              <section className="pz-course-modules__forum2--body">
                <div className="pz-course-modules__forum2--info">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    className="pz-course-modules__forum2--secondary"
                  ></FontAwesomeIcon>
                </div>
                <section>
                  <h4 className="pz-course-modules__forum2--title bold">
                    Foro
                  </h4>
                  <h5 className="pz-course-modules__forum2--description">
                    ¡Este curso tiene un foro donde puedes dejar preguntas!
                  </h5>
                </section>
              </section>
            </Link>
          </section>
        ) : null}
      </>
    );
};

export default withRouter(CourseModules);
