import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Line } from "components/ui";
import { connectReduxReducers } from "hocs";
import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { CONSTANTS } from "shared/constants";

const NavbarAdmin = ({ auth, index, history }) => {
  const { t } = useTranslation();
  return (
    <nav className="pz-navbar-admin">
      <section
        onClick={() => {
          history.push(CONSTANTS.URLS.ADMIN.HOME);
        }}
        className={
          index === 0 ? "pz-navbar-admin--active" : "pz-navbar-admin--item"
        }
        title={t(`admin.navbar.option1`)}
      >
        <h4 className="grid-columns grid-gap-10 grid-items-middle grid-content-center">
          <FontAwesomeIcon className="" icon={faHome} /> Home
        </h4>
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(CONSTANTS.URLS.ADMIN.USERS);
        }}
        className={
          index === 1 ? "pz-navbar-admin--active" : "pz-navbar-admin--item"
        }
        title={t(`admin.navbar.option1`)}
      >
        <h4>{t(`admin.navbar.option1`)}</h4>
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(CONSTANTS.URLS.ADMIN.CONTENT);
        }}
        className={
          index === 2 ? "pz-navbar-admin--active" : "pz-navbar-admin--item"
        }
        title={t(`admin.navbar.option2`)}
      >
        <h4>Contenido</h4>
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(CONSTANTS.URLS.ADMIN.TOOLS);
        }}
        className={
          index === 3 ? "pz-navbar-admin--active" : "pz-navbar-admin--item"
        }
        title="Tools"
      >
        <h4>Tools</h4>
      </section>
      <Line />
      <section
        onClick={() => {
          history.push(CONSTANTS.URLS.ADMIN.AI);
        }}
        className={
          index === 4 ? "pz-navbar-admin--active" : "pz-navbar-admin--item"
        }
        title="Banner"
      >
        <h4>PanzofIA</h4>
      </section>
    </nav>
  );
};

export default connectReduxReducers(withRouter(NavbarAdmin), "auth");
