import { CONSTANTS } from "shared/constants";
import API from "core/api";

const defaultState = {
  get_profile_request_status: CONSTANTS.FETCH.STANDBY,
  get_retention_request_status: CONSTANTS.FETCH.STANDBY,
  get_noretention_request_status: CONSTANTS.FETCH.STANDBY,
  get_login_request_status: CONSTANTS.FETCH.STANDBY,
  create_profile_request_status: CONSTANTS.FETCH.STANDBY,
  update_profile_request_status: CONSTANTS.FETCH.STANDBY,
  update_user_request_status: CONSTANTS.FETCH.STANDBY,
  get_course_subs_request_status: CONSTANTS.FETCH.STANDBY,
  create_questionaire_request_status: CONSTANTS.FETCH.STANDBY,
  get_questionaire_request_status: CONSTANTS.FETCH.STANDBY,
  create_trivia_attempt_request_status: CONSTANTS.FETCH.STANDBY,
};

const actionTypes = {
  PROFILE_REQUEST_STARTED: "PROFILE_REQUEST_STARTED",
  PROFILE_REQUEST_FINISHED: "PROFILE_REQUEST_FINISHED",
};

const requestStarted = (payload, dispatch) => {
  dispatch({ type: actionTypes.PROFILE_REQUEST_STARTED, payload });
};

const requestFinished = (payload, dispatch, response, callback) => {
  dispatch({ type: actionTypes.PROFILE_REQUEST_FINISHED, payload });
  callback(response);
};

const actions = {
  getProfile:
    (slug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_profile_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getProfile(slug, successCallback, errorCallback);
    },

  getProfiles:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_profile_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getProfiles(successCallback, errorCallback, filter);
    },

  getUsersLogin:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_login_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_login_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_login_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getUsersLogin(successCallback, errorCallback, filter);
    },

  getAdminQuestionnaires:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_login_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_login_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_login_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getAdminQuestionnaires(
        successCallback,
        errorCallback,
        filter
      );
    },

  getUsersRetention:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_retention_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_retention_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_retention_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getUsersRetention(successCallback, errorCallback, filter);
    },

  getUsersNoRetention:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_retention_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_retention_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_retention_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getUsersNoRetention(successCallback, errorCallback, filter);
    },

  getProfiles2:
    (filter = {}, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_profile_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_profile_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getProfiles2(successCallback, errorCallback, filter);
    },

  updateUser:
    (slug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          update_user_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            update_user_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            update_user_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateUser(slug, data, successCallback, errorCallback);
    },

  createProfile:
    (data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_profile_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_profile_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_profile_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createProfile(data, successCallback, errorCallback);
    },

  updateProfile:
    (userSlug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          update_profile_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            update_profile_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            update_profile_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.updateProfile(userSlug, data, successCallback, errorCallback);
    },

  getCourseSubscriptions:
    (id, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_course_subs_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_course_subs_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_course_subs_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getCourseSubscriptions(id, successCallback, errorCallback);
    },

  createQuestionnaire:
    (userSlug, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_questionaire_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_questionaire_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_questionaire_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createQuestionneire(
        userSlug,
        data,
        successCallback,
        errorCallback
      );
    },

  getQuestionnaires:
    (userSlug, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          get_questionaire_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            get_questionaire_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            get_questionaire_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.getQuestionneires(userSlug, successCallback, errorCallback);
    },

  createTriviaAttempt:
    (id_trivia, data, callback = () => {}) =>
    (dispatch) => {
      requestStarted(
        {
          create_trivia_attempt_request_status: CONSTANTS.FETCH.PROGRESS,
        },
        dispatch
      );

      const successCallback = (response) =>
        requestFinished(
          {
            create_trivia_attempt_request_status: CONSTANTS.FETCH.SUCCESS,
          },
          dispatch,
          response,
          callback
        );

      const errorCallback = (response) =>
        requestFinished(
          {
            create_trivia_attempt_request_status: CONSTANTS.FETCH.FAILED,
          },
          dispatch,
          response,
          callback
        );

      API.panzofi.createTriviaAttempt(
        id_trivia,
        data,
        successCallback,
        errorCallback
      );
    },
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_REQUEST_FINISHED:
      return {
        ...state,
        ...action.payload,
      };

    case actionTypes.PROFILE_REQUEST_STARTED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default {
  defaultState,
  actionTypes,
  actions,
  reducer,
};
