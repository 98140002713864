import React from "react";
import { CarouseRecent, Footer, Navbar } from "components/panzofi";
import { Button, Image } from "components/ui";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Page } from "components/common";
import videoprincipalV2 from "./videos/videoprincipalV2.mp4";
import cursos from "./cursos";
import { useTranslation } from "react-i18next";
import CarouselTestimony from "apps/landing/components/testimony-carousel";

const LandingHomePage = () => {
  const { t } = useTranslation();
  return (
    <main className="pz-landing">
      <Navbar />

      <Page>
        <div className="pz-principal">
          <section className="pz-landing__cover">
            <section className="pz-landing__cover--content animate__animated animate__bounceIn">
              <div className="pz-landing__content">
                <h1 className="text-center bold">
                  {t("landing.students.title")}
                </h1>
                <p>{t("landing.students.subtitle")}</p>
                <Button primary>
                  <Link
                    to={CONSTANTS.URLS.COURSES.ALL}
                    className="pz-landing__button"
                  >
                    {t("landing.students.button")}{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="pz-landing__button--icon"
                    />
                  </Link>
                </Button>
              </div>
              <div className="pz-landing__video-container">
                <video controls className="pz-landing__cover--video">
                  <source src={videoprincipalV2} type="video/mp4" />
                </video>
              </div>
            </section>
          </section>
        </div>
        <CarouseRecent
          coursesdomi={cursos}
          autoPlay={true}
          autoPlaySpeed={8000}
          title={t("landing.students.carrousel_title")}
          fullPage={false}
        />

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.ecosystem.title")}</h2>
            <p>{t("landing.students.ecosystem.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/network_help.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.ecosystem.item1.title")}
                  </h4>
                  <p>{t("landing.students.ecosystem.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/Format_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.ecosystem.item2.title")}
                  </h4>
                  <p>{t("landing.students.ecosystem.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/Location_issues.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.ecosystem.item3.title")}
                  </h4>
                  <p>{t("landing.students.ecosystem.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/wallet_issue.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.ecosystem.item4.title")}
                  </h4>
                  <p>{t("landing.students.ecosystem.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.features.title")}</h2>
            <p>{t("landing.students.features.subtitle")}</p>
          </section>
          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/format.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.features.item1.title")}
                  </h4>
                  <p>{t("landing.students.features.item1.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="ilustrations/corporate.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.features.item2.title")}
                  </h4>
                  <p>{t("landing.students.features.item2.subtitle")}</p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/profile.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.features.item3.title")}
                  </h4>
                  <p>{t("landing.students.features.item3.subtitle")}</p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="ilustrations/reviews.svg"
                  className="pz-landing__how-works--item--secondimage"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.features.item4.title")}
                  </h4>
                  <p>{t("landing.students.features.item4.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <CarouselTestimony
          autoPlay={false}
          title={t("landing.students.carrousel_testimony_title")}
        />

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.how_works.title")}</h2>
            <p>
              <a
                target="_top"
                rel="noopener noreferrer"
                href={"https://www.panzofi.com/cursos/tutorial-panzofi"}
              >
                {t("landing.students.how_works.subtitle")}
              </a>{" "}
            </p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <h3 className="bold">
                {t("landing.students.how_works.without_acount.title")}
              </h3>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/navigate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.without_acount.item1.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.students.how_works.without_acount.item1.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/search.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.without_acount.item2.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.students.how_works.without_acount.item2.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/find.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.without_acount.item3.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.students.how_works.without_acount.item3.subtitle"
                    )}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item invert">
                <Image
                  name="icons/share.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.without_acount.item4.title")}
                  </h4>
                  <p>
                    {t(
                      "landing.students.how_works.without_acount.item4.subtitle"
                    )}
                  </p>
                </section>
              </article>
            </section>
            <section className="pz-landing__how-works--column">
              <h3 className="bold">
                {t("landing.students.how_works.with_acount.title")}
              </h3>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/rate.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.with_acount.item1.title")}
                  </h4>
                  <p>
                    {t("landing.students.how_works.with_acount.item1.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/upload.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.with_acount.item2.title")}
                  </h4>
                  <p>
                    {t("landing.students.how_works.with_acount.item2.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/participation.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.with_acount.item3.title")}
                  </h4>
                  <p>
                    {t("landing.students.how_works.with_acount.item3.subtitle")}
                  </p>
                </section>
              </article>
              <article className="pz-landing__how-works--item">
                <Image
                  name="icons/winner.svg"
                  className="pz-landing__how-works--item--image"
                />
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.how_works.with_acount.item4.title")}
                  </h4>
                  <p>
                    {t("landing.students.how_works.with_acount.item4.subtitle")}
                  </p>
                </section>
              </article>
            </section>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.havent_found.title")}</h2>
            <p>{t("landing.students.havent_found.subtitle")}</p>
          </section>

          <section className="pz-landing__services">
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/Content_creators.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.students.havent_found.item.title")}
                </h3>
                <p>{t("landing.students.havent_found.item.subtitle")}</p>
                <h4 className="bold">
                  {t("landing.students.havent_found.item.subtitle2")}
                </h4>
                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.TEACHERS}>
                    {t("landing.students.havent_found.item.button")}
                  </Link>
                </Button>
              </section>
            </article>
          </section>
        </section>

        <section className="pz-landing__section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.services.title")}</h2>
            <p>{t("landing.students.services.subtitle")}</p>
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/support-consultant.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.students.services.item3.title")}
                </h3>
                <p>{t("landing.students.services.item3.subtitle")}</p>
                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.SERVICES}>
                    {t("landing.students.services.item3.button")}
                  </Link>
                </Button>
              </section>
            </article>
            <br />
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/university.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.students.services.item2.title")}
                </h3>
                <p>{t("landing.students.services.item2.subtitle")}</p>
                <h4 className="bold">
                  {t("landing.students.services.item2.subtitle2")}
                </h4>
                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.UNIVERSITIES}>
                    {t("landing.students.services.item2.button")}
                  </Link>
                </Button>
              </section>
            </article>
            <br />
            <br />
            <article className="pz-landing__services--service">
              <Image
                name="ilustrations/Companies_offer.svg"
                className="pz-landing__services--service--image"
              />
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.students.services.item1.title")}
                </h3>
                <p>{t("landing.students.services.item1.subtitle")}</p>
                <h4 className="bold">
                  {t("landing.students.services.item1.subtitle2")}
                </h4>
                <Button primary ghost thin>
                  <Link target="_top" to={CONSTANTS.URLS.LANDING.COMPANIES}>
                    {t("landing.students.services.item1.button")}
                  </Link>
                </Button>
              </section>
            </article>
          </section>
        </section>

        <section className="pz-landing__section pz-landing__services-section">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.recognitions.title")}</h2>
            <p>{t("landing.students.recognitions.subtitle")}</p>
          </section>

          <section className="pz-landing__services">
            <article className="pz-landing__services--service">
              <section>
                <Image
                  name="ilustrations/aws-member-tier.svg"
                  className="pz-landing__how-works--item--thirdimage"
                />
              </section>
              <section className="pz-landing__services--service--content">
                <h3 className="bold">
                  {t("landing.students.recognitions.item.title")}
                </h3>
                <p>{t("landing.students.recognitions.item.subtitle")}</p>
                <h4 className="bold">
                  {t("landing.students.recognitions.item.bold")}
                </h4>
                <p>{t("landing.students.recognitions.item.subtitle2")}</p>
                <p>
                  {t("landing.students.recognitions.item.subtitle3")}{" "}
                  <a
                    target="_top"
                    rel="noopener noreferrer"
                    href={"https://aws.amazon.com/es/education/edstart/"}
                  >
                    <strong>
                      {t("landing.students.recognitions.item.bold2")}
                    </strong>
                  </a>{" "}
                </p>
              </section>
            </article>
          </section>
        </section>

        <section className="pz-landing__section ">
          <section className="text-center">
            <h2 className="bold">{t("landing.students.experts.title")}</h2>
            <p>{t("landing.students.experts.subtitle")}</p>
          </section>

          <section className="pz-landing__how-works">
            <section className="pz-landing__how-works--column right">
              <article className="pz-landing__how-works--item">
                <Link
                  target="_top"
                  to={CONSTANTS.URLS.PROFILE.USER_DETAIL("negu")}
                >
                  <Image
                    name="ilustrations/companies/negu.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                </Link>
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.experts.item1.title")}
                  </h4>
                  <p>{t("landing.students.experts.item1.subtitle")}</p>
                </section>
              </article>
            </section>

            <section className="pz-landing__how-works--column">
              <article className="pz-landing__how-works--item">
                <Link
                  target="_top"
                  to={CONSTANTS.URLS.PROFILE.USER_DETAIL("discooperis")}
                >
                  <Image
                    name="ilustrations/companies/disco.svg"
                    className="pz-landing__how-works--item--secondimage"
                  />
                </Link>
                <section className="pz-landing__how-works--item--content">
                  <h4 className="bold">
                    {t("landing.students.experts.item2.title")}
                  </h4>
                  <p>{t("landing.students.experts.item2.subtitle")}</p>
                </section>
              </article>
            </section>
          </section>
        </section>
      </Page>

      <Footer />
    </main>
  );
};

export default LandingHomePage;
