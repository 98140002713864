import React from "react";
import { Button, Image } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLink,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";
import {
  ActiveAccountModal,
  Activities,
  BuyPackageModal,
} from "apps/profile/components";
import { Link } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import ShareButton from "../sharebutton";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";

const ProfileCard = ({ isowner, user, getUserProfile, onClickToEdit }) => {
  const [activeAccountModalVisible, setActiveAccountModalVisible] =
    React.useState(false);
  const [buyModalVisible, setBuyModalVisible] = React.useState(false);
  const { t } = useTranslation();

  const closeModal = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setActiveAccountModalVisible(false);
  };

  const closeBuyModal = (shouldGetProfile = false) => {
    if (shouldGetProfile) {
      getUserProfile();
    }
    setBuyModalVisible(false);
  };

  if (user?.profile) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.profile?.picture ? (
            <Image
              className="pz-profile-card__image"
              src={user?.profile?.picture}
            />
          ) : (
            <Image
              className="pz-profile-card__image"
              name="icons/male-avatar.svg"
            />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h5>{user?.slug}</h5>
          <h1 className="pz-profile-card__header--title">
            <span>{user?.full_name}</span>
            {typeof onClickToEdit === "function" ? (
              <FontAwesomeIcon
                className="edit"
                icon={faEdit}
                onClick={onClickToEdit}
              />
            ) : null}
          </h1>
        </section>
        {user?.profile?.introduction ? (
          <>
            <section className="pz-profile-card__body">
              <section>
                <h4 className="bold">{t("profile.experience.about")}</h4>
                <p style={{ wordBreak: "break-word" }}>
                  {user?.profile?.introduction}
                </p>
              </section>
            </section>
            <section className="pz-profile-card__links">
              <h5 className="pz-profile-card__link">
                <FontAwesomeIcon icon={faLink} />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={user?.profile?.website}
                >
                  {user?.profile?.website}
                </a>
              </h5>
              <h5 className="pz-profile-card__link">
                <FontAwesomeIcon icon={faLocationArrow} />
                {user?.profile?.location}
              </h5>
              <h5 className="pz-profile-card__link">
                <ShareButton />
              </h5>
            </section>
          </>
        ) : null}
        <section className="pz-profile-card__detail">
          <h3 className="bold">{t("profile.experience.title")}</h3>
          <section className="pz-profile-card__experiences">
            <article className="pz-profile-card__experience">
              {user?.profile?.apprentice_exp >=
              CONSTANTS.BADGES_GOALS.STUDENT.BRONCE ? (
                <Image
                  name={
                    user?.profile?.apprentice_exp >=
                    CONSTANTS.BADGES_GOALS.STUDENT.SILVER
                      ? user?.profile?.apprentice_exp >=
                        CONSTANTS.BADGES_GOALS.STUDENT.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.STUDENT.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.STUDENT.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.apprentice_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_student")}</h5>
            </article>
            <article className="pz-profile-card__experience">
              {user?.profile?.interaction_exp >=
              CONSTANTS.BADGES_GOALS.INTERACTION.BRONCE ? (
                <Image
                  name={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.INTERACTION.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.INTERACTION.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.INTERACTION.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.INTERACTION.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.interaction_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_panzofi")}</h5>
            </article>
            <article className="pz-profile-card__experience">
              {user?.profile?.professor_exp >=
              CONSTANTS.BADGES_GOALS.PROFESSOR.BRONCE ? (
                <Image
                  name={
                    user?.profile?.professor_exp >=
                    CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                      ? user?.profile?.professor_exp >=
                        CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                        ? "ilustrations/medals/ORO.svg"
                        : "ilustrations/medals/PLATA.svg"
                      : "ilustrations/medals/BRONCE.svg"
                  }
                  title={
                    user?.profile?.interaction_exp >=
                    CONSTANTS.BADGES_GOALS.PROFESSOR.SILVER
                      ? user?.profile?.interaction_exp >=
                        CONSTANTS.BADGES_GOALS.PROFESSOR.GOLD
                        ? "Maestro"
                        : "Experto"
                      : "Talento Emergente"
                  }
                  className="pz-profile-card__experience--badge"
                />
              ) : null}
              <h1 className="pz-profile-card__experience--number">
                {user?.profile?.professor_exp || 0}
              </h1>
              <h5 className="bold">{t("profile.experience.pts_profesor")}</h5>
            </article>
          </section>
          {getUserProfile ? (
            <Activities
              quest={user.questionare}
              user_slug={user.slug}
              getUserProfile={getUserProfile}
            />
          ) : null}
        </section>
      </section>
    );
  }

  if (user?.employee) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.employee?.picture ? (
            <Image
              className="pz-profile-card__image"
              src={user?.employee?.picture}
            />
          ) : (
            <Image className="pz-profile-card__image" name="icons/comp.svg" />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h5>{user?.slug}</h5>
          <h1 className="bold">{user?.full_name}</h1>
          <section className="pz-profile-card__body">
            Para acceder a las lecciones, haz clic en el logo de{" "}
            {user?.employee?.organization?.user_name} o Panzofi.
          </section>
        </section>

        <section className="pz-profile-card__detail">
          <h3 className="bold">Mi organización</h3>
          <section className="pz-profile-card__experiences">
            <Link
              to={CONSTANTS.URLS.PROFILE.USER_DETAIL(
                user?.employee?.organization?.slug
              )}
              className="pz-profile-card__experience"
            >
              {user?.employee?.organization?.logo ? (
                <Image
                  className="pz-profile-card__experience--image"
                  src={user?.employee?.organization?.logo}
                />
              ) : (
                <Image
                  className="pz-profile-card__experience--image"
                  name="icons/comp.svg"
                />
              )}
              <h5 className="bold">
                {user?.employee?.organization?.user_name}
              </h5>
            </Link>
          </section>
        </section>
        {user?.employee?.organization?.tier_plan?.name !== "FREE" ? (
          <section className="pz-profile-card__detail2">
            <h3 className="bold">Panzofi</h3>
            <section className="pz-profile-card__experiences">
              <Link
                to={CONSTANTS.URLS.PROFILE.USER_DETAIL("panzofi")}
                className="pz-profile-card__experience"
              >
                {user?.employee?.organization?.logo ? (
                  <Image
                    className="pz-profile-card__experience--imageComp"
                    name="logos/panzofi.png"
                  />
                ) : (
                  <Image
                    className="pz-profile-card__experience--imageComp"
                    name="logos/panzofi.png"
                  />
                )}
                <h5 className="bold">{"Panzofi"}</h5>
              </Link>
            </section>
          </section>
        ) : (
          <section className="pz-profile-card__detail2">
            <h5 className="bold">
              Solicita a tu organización la adquisición de Panzofi Basic.
            </h5>
          </section>
        )}
      </section>
    );
  }

  if (user?.organization) {
    return (
      <section className="pz-profile-card">
        <section className="pz-profile-card__photo">
          {user?.organization?.logo ? (
            <Image
              className="pz-profile-card__image"
              src={user?.organization?.logo}
            />
          ) : (
            <Image className="pz-profile-card__image" name="icons/comp.svg" />
          )}
        </section>
        <section className="pz-profile-card__header">
          <h1 className="bold">{user?.full_name}</h1>
        </section>

        <section className="pz-profile-card__body">
          <section>
            {user?.organization?.is_active ? (
              <>
                {user?.organization?.tier_plan?.name !== "FREE" && isowner ? (
                  <p>
                    Tu paquete está <span className="bold">activo</span>; tienes
                    la opción de añadir empleados que podrán acceder a los
                    beneficios de Panzofi Business.
                    {user?.organization?.tier_plan?.name === "ENTERPRISE" ? (
                      <>
                        <p>
                          Gracias a tu paquete Entreprise podemos crear cursos
                          especialmente para ti
                        </p>
                        <Button primary ghost thin>
                          <a
                            target="_top"
                            rel="noopener noreferrer"
                            href={
                              "https://share.hsforms.com/1CztGCIlKQv2KF84W4E9GHAbptug"
                            }
                          >
                            Pedir curso adicional
                          </a>
                        </Button>
                      </>
                    ) : null}
                    <br />
                    <br />
                    <small>
                      Tienes el paquete{" "}
                      <span className="bold">
                        {user?.organization.tier_plan.name}
                      </span>
                      <br /> El siguiente pago se realizará antes del{" "}
                      {moment(user?.organization?.expiration_date).format(
                        "D MMMM, YYYY"
                      )}
                    </small>
                  </p>
                ) : isowner ? (
                  <p>
                    Tu cuenta está <span className="bold">activa</span>, pero es
                    necesario que realices el pago de una de las suscripciones
                    disponibles{" "}
                    <span className="bold">(Basic, Business o Enterprise)</span>{" "}
                    para poder disfrutar de los beneficios. Si necesitas
                    asistencia, no dudes en contactar a info@panzofi.com.
                  </p>
                ) : (
                  <p>
                    En {user.first_name}, creemos en el poder del aprendizaje
                    continuo para impulsar tanto el crecimiento personal como
                    profesional. Te invitamos a aprender, compartir y crecer
                    junto a nosotros.
                  </p>
                )}
                {user?.organization?.tier_plan?.name === "FREE" && isowner ? (
                  <Button
                    primary
                    thin
                    onClick={() => {
                      setBuyModalVisible(true);
                    }}
                  >
                    Comprar paquete
                  </Button>
                ) : // <Button primary ghost thin>
                //   <a
                //     target="_top"
                //     rel="noopener noreferrer"
                //     href={
                //       "https://share.hsforms.com/1vAJ_tNrAQtSmsHnQiGzwwwbptug"
                //     }
                //   >
                //     Comprar paquete
                //   </a>
                // </Button>
                null}
              </>
            ) : (
              <>
                <p>
                  Tu cuenta <span className="bold">no esta activa</span> aún, Se
                  ha enviado un correo electrónico con el código de activación.
                  Si necesitas asistencia o deseas adquirir un plan, por favor
                  contacta a{" "}
                  <a className="bold" href="mailto:info@panzofi.com">
                    info@panzofi.com.
                  </a>{" "}
                </p>
                <Button
                  primary
                  thin
                  onClick={() => {
                    setActiveAccountModalVisible(true);
                  }}
                >
                  Activar cuenta
                </Button>
              </>
            )}
          </section>
        </section>
        <section className="pz-profile-card__links">
          <h5 className="pz-profile-card__link">
            <FontAwesomeIcon icon={faLink} />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={user?.organization?.website}
            >
              {user?.organization?.website}
            </a>
          </h5>
        </section>

        <section className="pz-profile-card__detail">
          {user?.organization?.tier_plan?.name !== "FREE" ? (
            <section className="pz-profile-card__detail2">
              <h3 className="bold">Panzofi</h3>
              <section className="pz-profile-card__experiences">
                <Link
                  to={CONSTANTS.URLS.PROFILE.USER_DETAIL("panzofi")}
                  className="pz-profile-card__experience"
                >
                  {user?.employee?.organization?.logo ? (
                    <Image
                      className="pz-profile-card__experience--imageComp"
                      name="logos/panzofi.png"
                    />
                  ) : (
                    <Image
                      className="pz-profile-card__experience--imageComp"
                      name="logos/panzofi.png"
                    />
                  )}
                  <h5 className="bold">{"Panzofi"}</h5>
                </Link>
              </section>
            </section>
          ) : (
            <section className="pz-profile-card__detail2">
              <h5 className="bold">
                Solicita a tu organización la adquisición de Panzofi Basic.
              </h5>
            </section>
          )}
        </section>

        <ActiveAccountModal
          is_visible={activeAccountModalVisible}
          closeModal={closeModal}
        />
        <BuyPackageModal
          is_visible={buyModalVisible}
          closeModal={closeBuyModal}
        />
      </section>
    );
  }

  return null;
};

export default ProfileCard;
