export const CONSTANTS = {
  DOM_ROOTS: {
    ROOT_APP: document.getElementById("root-app"),
    ROOT_MODAL: document.getElementById("root-modal"),
  },
  FETCH: {
    STANDBY: "FETCH_STANDBY",
    PROGRESS: "FETCH_IN_PROGRESS",
    SUCCESS: "FETCH_SUCCESS",
    FAILED: "FETCH_FAILED",
  },
  FILE_TYPES: {
    VIDEO: "VID",
    AUDIO: "AUD",
    DOCUMENT: "DOC",
    IMAGE: "IMG",
  },
  APP_MODELS: {
    COURSES: "course",
    CORE_CONTENT: "corecontent",
    PRACTICAL_CONTENT: "practicalcontent",
    POSTS: "post",
    THREAD: "thread",
  },
  REACTIONS: {
    LIKE: "LIK",
    INTRIGUING: "ITG",
    FUN: "FUN",
    INTERESTING: "ITT",
    LOVE_IT: "LOV",
  },
  COOKIES: {
    ALLCOOKIES: "allCookiesPZ",
    NECESSARYCOOKIES: "neceCookiesPZ",
    FUNCTIONALCOOKIES: "funcCookiesPZ",
    PERFORMANCECOOKIES: "perfCookiesPZ",
    TARGETCOOKIES: "targCookiesPZ",
    BANNERCOOKIES: "bannCookiesPZ",
  },
  BADGES_GOALS: {
    STUDENT: {
      BRONCE: 30,
      SILVER: 100,
      GOLD: 170,
    },
    INTERACTION: {
      BRONCE: 30,
      SILVER: 100,
      GOLD: 170,
    },
    PROFESSOR: {
      BRONCE: 30,
      SILVER: 150,
      GOLD: 250,
    },
  },
  URLS: {
    PROFILE: {
      USER_LOGGED: "/perfil",
      USER_DETAIL: (slug) => `/perfil/${slug}`,
      USER_RESULTS: (slug, id) => `/perfil/${slug}/results/${id}`,
      USER_DETAIL_SETTINGS: (slug) => `/perfil/${slug}/settings`,
      USER_DETAIL_SETTINGS_PROFILE: (slug) => `/perfil/${slug}/settingsProfile`,
      USER_DETAIL_SETTINGS_PASSWORD: (slug) =>
        `/perfil/${slug}/settingsPassword`,
      USER_DETAIL_SETTINGS_DELETE: (slug) => `/perfil/${slug}/settingsDelete`,
    },
    AUTH: {
      LOGIN: "/auth/login",
      FORGOT_PASSWORD: "/auth/forgot-password",
      CHANGE_PASSWORD: "/auth/change-password",
      CHANGE_PASSWORD2: "/auth/change-password2",
      SIGNUP: "/auth/signup",
      SIGNUP_COMPANY: "/auth/signup/company",
    },
    SOCIAL: {
      EMAIL: "mailto:info@panzofi.com",
      LINKEDIN: "https://www.linkedin.com/company/panzofi/",
      YOUTUBE: "https://www.youtube.com/channel/UCILiVwkkG4Q-4Ub9qIjO1YA",
      FACEBOOK: "https://www.facebook.com/panzofi/",
      TWITTER: "",
      INSTAGRAM: "https://instagram.com/panzofi/",
      TIKTOK: "https://www.tiktok.com/@panzofi/",
    },
    LANDING: {
      HOME: "/",
      TEACHERS: "/info/creadores-contenido",
      COMPANIES: "/info/empresas",
      BUSINESSPARTNERS: "/info/socios-negocio",
      SERVICES: "/info/servicios",
      CONSULTANT: "/info/asesoria",
      AUDIOVISUAL: "/info/asesoria/audiovisual",
      MARKETING: "/info/asesoria/marketing",
      TRAINING: "/info/asesoria/training",
      UNIVERSITIES: "/info/instituciones-educativas",
      WORK_WITH_US: "https://forms.gle/Ams6SP6RUxwWw8CN7",
      BLOG: "/info/blog",
      CONTACT_US: "/info/contactanos",
      FAQ: "/info/faq",
      REPORT_ERROR: "https://forms.gle/Ams6SP6RUxwWw8CN7",
    },
    COURSES: {
      ALLPage: "/cursos/all",
      ALL: "/cursos",
      POSTS: "/fastlearning",
      COURSE_DETAIL: (slug) => `/cursos/${slug}`,
      LESSON: (slug, module, lesson) => `/cursos/${slug}/${module}/${lesson}`,
    },
    FORUM: {
      ALL: "/forum",
      FORUM_DETAIL: (slug) => `/forum/${slug}`,
      THREAD_DETAIL: (slug, id) => `/forum/${slug}/thread/${id}`,
    },
    AI: {
      MAIN: "/panzofia",
    },
    ADMIN: {
      HOME: "/admin",
      USERS: "/admin/users",
      CONTENT: "/admin/content",
      TOOLS: "/admin/tools",
      AI: "/admin/ais",
    },
  },
};
