import React from "react";
import API from "core/api";
import { Redirect } from "react-router-dom";
import { CONSTANTS } from "shared/constants";
import { connectReduxReducers } from "hocs";

const LoggedNoB2BGuard = (Component) => {
  class LoggedNoB2BGuardHOC extends React.Component {
    componentDidMount() {
      if (API.auth.isLoggedIn()) {
        this.props.auth.validate_token();
      }
    }

    render() {
      if (!!!API.auth.isLoggedInAsB2B()) {
        return <Component {...this.props} />;
      } else {
        return <Redirect to={CONSTANTS.URLS.AUTH.LOGIN} />;
      }
    }
  }
  return connectReduxReducers(LoggedNoB2BGuardHOC, "auth");
};

export default LoggedNoB2BGuard;
